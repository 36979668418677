import React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Person from "@material-ui/icons/Person";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

const ChangeManagerDialog = ({ open, handleClose, managerslist, handleListItemClick }) => {
    const classes = useStyles();

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">Change Manager</DialogTitle>
            <List>
                {managerslist.map((item) => (
                    <ListItem
                        button
                        onClick={(e) => handleListItemClick(e, item)}
                        key={item.user_id}
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <Person />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.username} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};

export default ChangeManagerDialog;
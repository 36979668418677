import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import ProspectWise from "./ProspectWise";
import CallDetailWise from "./CallDetailWise";
import ErrorBoundary from "../../hoc/ErrorBoundary/ErrorBoundary";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const useStyles = makeStyles(dashboardStyle);

const Dashboard = (props) => {
    const classes = useStyles();
    const { user, persona, configSettings, history } = props;
    const [activeTab, setActiveTab] = useState(0);
    const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
    const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));
    const [isReload, setIsReload] = useState(0);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tab = urlParams.get('tab');
        setActiveTab(parseInt(tab || '0', 10)); // Default to 0 if tab is null
    }, []);

    const handleChange = (event, value) => {
        setActiveTab(value);
        history.push('?tab=' + value);
    };

    const handleDateChange = (e) => {
        setStartDate(e.startDate);
        setEndDate(e.endDate);
    };

    const handleFetchClick = () => {
        setIsReload(prevIsReload => prevIsReload + 1);
    };

    // Define common query parameters
    const commonQuery = {
        admin: user && user.admin
    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                        style="dashboard"
                        id="startdate"
                        labelProps={{ shrink: true }}
                        formControlProps={{
                            fullWidth: true,
                            multiline: true
                        }}
                        inputProps={{
                            type: 'date_range',
                            initialDateRange: {
                                startDate: startDate,
                                endDate: endDate
                            },
                            maxDate: new Date(),
                            onChange: handleDateChange,
                            showFetch: true,
                            onClick: handleFetchClick
                        }}
                    />
                </GridItem>

                {/* Storage Information Card (Commented out) */}
                {/* <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader>
                            <h3>Storage Information</h3>
                            <p>1 Record takes 1500 Byte</p>
                        </CardHeader>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={2}>
                                <InfoBox
                                    label="Total Prospects"
                                    icon="fa-solid fa-database fa-4x"
                                    root="prospects"
                                    color="info"
                                    query={{ ...commonQuery }}
                                    user={user}
                                    small={false}
                                    isReload={isReload}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={2}>
                                <InfoBox
                                    label="Total Calldetails"
                                    icon="fa-solid fa-database fa-4x"
                                    root="CallDetails"
                                    color="info"
                                    query={{ ...commonQuery }}
                                    user={user}
                                    small={false}
                                    isReload={isReload}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={2}>
                                <InfoBox
                                    label="Total Call History"
                                    icon="fa-solid fa-database fa-4x"
                                    root="CallHistory"
                                    color="info"
                                    query={{ ...commonQuery }}
                                    user={user}
                                    small={false}
                                    isReload={isReload}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={2}>
                                <InfoBox
                                    label="Total Schedule History"
                                    icon="fa-solid fa-database fa-4x"
                                    root="ScheduleHistory"
                                    color="info"
                                    query={{ ...commonQuery }}
                                    user={user}
                                    small={false}
                                    isReload={isReload}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={2}>
                                <InfoBox
                                    label="Total Call Recordings"
                                    icon="fa-solid fa-database fa-4x"
                                    root="CallRecording"
                                    color="info"
                                    query={{ ...commonQuery }}
                                    user={user}
                                    small={false}
                                    isReload={isReload}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={2}>
                                <InfoBox
                                    label="Total Recording Size"
                                    icon="fa-solid fa-database fa-4x"
                                    root="CallRecording"
                                    calculate={true}
                                    color="info"
                                    query={{ ...commonQuery }}
                                    user={user}
                                    small={false}
                                    isReload={isReload}
                                />
                            </GridItem>
                        </GridContainer>
                    </Card>
                </GridItem> */}

                <GridItem xs={12} sm={12} md={12}>
                    <CustomTabs
                        title=""
                        TabProps={{
                            onChange: handleChange,
                        }}
                        selectedValue={activeTab}
                        headerColor="info"
                        tabs={[
                            {
                                tabName: "Call Detail Wise",
                                tabContent: (
                                    <ErrorBoundary name="CallDetailWise">
                                        <CallDetailWise
                                            user={user}
                                            configSettings={configSettings}
                                            startDate={startDate}
                                            endDate={endDate}
                                            isReload={isReload}
                                        />
                                    </ErrorBoundary>
                                ),
                            },
                            ...(persona !== 'Dialling' ? [
                                {
                                    tabName: "Prospect Wise",
                                    tabContent: (
                                        <ErrorBoundary name="ProspectWise">
                                            <ProspectWise
                                                user={user}
                                                history={history}
                                                startDate={startDate}
                                                endDate={endDate}
                                                isReload={isReload}
                                            />
                                        </ErrorBoundary>
                                    ),
                                }
                            ] : [])
                        ]}
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
};

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object,
    persona: PropTypes.string,
    configSettings: PropTypes.object,
    history: PropTypes.object.isRequired
};

export default Dashboard;
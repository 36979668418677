import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Badge, Box } from "@material-ui/core";
import LikeDislikeComponent from 'components/Reaction/LikeDislikeComponent'; // Adjust path as needed


const LeadScorePopup = ({ calculatedScore }) => {
    const [open, setOpen] = useState(false);
    const [aiContent, setAiContent] = useState("");

    // Function to generate AI content dynamically
    const generateLeadSummary = (calculatedScore) => {
        const { defaultScore, finalScore, scoringReasons } = calculatedScore;
    
        let response = `📊 **Lead Score Summary**\n`;
        response += `🔹 **Final Score:** ${finalScore} / ${defaultScore}\n\n`;
        response += `**🔍 Score Breakdown:**\n`;
    
        // Mapping category descriptions dynamically
        const categoryDescriptions = {
            disposition: "Disposition Impact",
            entryBase: "Initial Engagement",
            talktime: "Call Duration",
            custom: "Custom Factors",
            remarketingImpact: "Remarketing Effect",
            resetOnDisposition: "Score Adjustments",
        };
    
        // Dynamically generate content for each category
        Object.keys(scoringReasons).forEach((category) => {
            if (scoringReasons[category].length > 0) {
                scoringReasons[category].forEach(reason => {
                    let description = categoryDescriptions[category] || category;
                    let scoreImpact = reason.score > 0 ? `+${reason.score}` : `${reason.score}`;
                    
                    response += `✅ **${description}:** ${scoreImpact} (*${reason.rule}*)\n`;
    
                    // Adding facts if available
                    if (reason.facts) {
                        Object.entries(reason.facts).forEach(([key, value]) => {
                            let factData = JSON.parse(value);
                            response += `   - *${key.replace(/([A-Z])/g, ' $1')}:* ${factData.factResult}\n`;
                        });
                    }
                });
            }
        });
    
        // Final Recommendation
        response += ``;
        if (finalScore < 50) {
            response += `- The lead score is below average. Consider a follow-up via email, WhatsApp, or a direct call.\n`;
        } else {
            response += `- This lead shows strong potential! Engage with a personalized offer or direct sales outreach.\n`;
        }
    
        response += `\nWould you like me to analyze another lead? 😊`;
    
        return response;
    };

    // Handle Button Click (Open Popup & Set AI Content)
    const handleClickOpen = () => {
        setAiContent(generateLeadSummary(calculatedScore)); // Set AI content dynamically
        setOpen(true);
    };

    // Handle Close
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {/* Button to Open Popup */}
            <Box style={{ textAlign: "center" }}>
                <Badge badgeContent="beta" color="secondary">
                    <Button variant="contained" color="primary" onClick={handleClickOpen}>
                        <i className="fa fa-magic" aria-hidden="true" style={{ color: "#fff000" }}></i>{" "}
                        Lead Scoring By BeTyphon AI Agent
                    </Button>
                </Badge>
            </Box>

            {/* Popup Dialog */}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>🤖 AI Generated Lead Scoring Insights</DialogTitle>
                <DialogContent>
                    <Typography style={{ whiteSpace: "pre-line" }}>{aiContent}</Typography>
                </DialogContent>
                <DialogActions>
                    <LikeDislikeComponent
                        itemId="LeadScore" // Assuming your item has an _id
                        itemType="LeadScoreReport" // Define a unique itemType
                    />
                    <Button onClick={handleClose} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LeadScorePopup;
import React from "react";
import { Typography, Divider } from "@material-ui/core";
import DispositionSelector from "./DispositionSelector";

const ResetOnDispositionSection = ({ resetOnDisposition, onChange }) => {
    const handleConfirmSelection = (selectedItems) => {
        let obj = [];
        for (let index = 0; index < selectedItems.length; index++) {
            const element = selectedItems[index];
            obj.push(element);
        }

        onChange([...resetOnDisposition, ...(obj.length > 1 ? [obj[obj.length - 1]] : [obj[0]])]);
        console.log("ResetOnDispositionSection", resetOnDisposition);
    };

    return (
        <div>
            <Divider style={{ margin: "20px 0" }} />
            <Typography variant="h5">Reset On Disposition</Typography>

            <DispositionSelector disposition={resetOnDisposition} onSelectDisposition={handleConfirmSelection} />
        </div>
    );
};

export default ResetOnDispositionSection;
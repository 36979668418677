import React from "react";
import { Typography, Divider, Button } from "@material-ui/core";
import RuleRow from "./RuleRow";
import CustomInput from "components/CustomInput/CustomInput.jsx";

const CategorySection = ({ category, total, rules, onTotalChange, onRuleChange, onAddRule, onRemoveRule }) => {
    return (
        <div>
            <Divider style={{ margin: "20px 0" }} />
            <Typography variant="h5" style={{ textTransform: "capitalize" }}>
                {category.replace(/([A-Z])/g, " $1").trim()}
            </Typography>

            <CustomInput
                labelText="Total Score Allocation"
                id="totalScore"
                formControlProps={{
                    fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                    type: "number",
                    value: total,
                    onChange: (e) => onTotalChange(Number(e.target.value)),
                }}
            />

            {rules.map((rule, index) => (
                <RuleRow
                    key={index}
                    rule={rule}
                    onRuleChange={(event) => onRuleChange(index, event)}
                    onRemoveRule={() => onRemoveRule(index)}
                />
            ))}

            <Button onClick={onAddRule}>+ Add Rule</Button>
        </div>
    );
};

export default CategorySection;
import React, { useState, useEffect } from "react";
import { getLeadScoringConfigs } from "services/leadscoring/api.js";
import { Card, CardContent, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import LeadScoringForm from "./LeadScoringForm";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const LeadScoringList = ({ admin }) => {
    const [configs, setConfigs] = useState([]);
    const [openAddConfigModal, setOpenAddConfigModal] = useState(false);
    const [openEditConfigModal, setOpenEditConfigModal] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState(null);

    const fetchConfigs = async () => {
        try {
            const data = await getLeadScoringConfigs();
            setConfigs(data);
        } catch (error) {
            console.error("Error fetching configurations", error);
        }
    };
    useEffect(() => {

        fetchConfigs();
    }, [admin]);

    const handleOpenAddConfigModal = () => {
        setOpenAddConfigModal(true);
    };

    const handleCloseAddConfigModal = () => {
        setOpenAddConfigModal(false);
    };

    const handleOpenEditConfigModal = (config) => {
        setSelectedConfig(config);
        setOpenEditConfigModal(true);
        fetchConfigs();
    };

    const handleCloseEditConfigModal = () => {
        setOpenEditConfigModal(false);
        setSelectedConfig(null);
        fetchConfigs();
    };


    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h3>
                        Lead Scoring Configurations
                        <span style={{ float: "right", fontSize: "15px" }}>
                            <Button size="sm" color="primary" onClick={handleOpenAddConfigModal}>
                                Add New Configuration
                            </Button>
                        </span>
                    </h3>
                </GridItem>
                {configs.map((config) => (
                    <GridItem xs={12} sm={12} md={12} key={config._id}>
                        <Card style={{ marginBottom: "20px" }}>
                            <CardContent>
                                {/* Basic Configuration Info - Displayed outside Accordion Summary */}
                                <Typography variant="h6">{`Config Name: ${config.name}`}</Typography>
                                <Typography variant="body2">{`Default Score: ${config.defaultScore}`}</Typography>

                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        {/* Concise Summary in Accordion Summary */}
                                        <Typography variant="subtitle1">
                                            Summary: {Object.keys(config.attributes).length} Attributes, {Object.keys(config.remarketingImpact).length} Remarketing Impacts, {config.resetOnDisposition.length} Reset Dispositions
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            {/* Attributes Section */}
                                            <Typography variant="subtitle1" style={{ marginTop: "10px" }}>Attributes:</Typography>
                                            {Object.entries(config.attributes).map(([category, details]) => (
                                                <div key={category} style={{ marginLeft: "20px" }}>
                                                    <Typography variant="body2"><strong>{category}</strong> (Total Score: {details.total})</Typography>
                                                    {details.rules.map((rule, index) => (
                                                        <div key={index} style={{ marginLeft: "20px" }}>
                                                            <Typography variant="body2">
                                                                Rule {index + 1}: {rule.field} {rule.operator} {Array.isArray(rule.value) ? rule.value.map(v => v.disposition_value).join(", ") : rule.value} (Score: {rule.score})
                                                            </Typography>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}

                                            {/* Remarketing Impact Section */}
                                            <Typography variant="subtitle1" style={{ marginTop: "10px" }}>Remarketing Impact:</Typography>
                                            {Object.entries(config.remarketingImpact).map(([channel, score]) => (
                                                <Typography key={channel} variant="body2" style={{ marginLeft: "20px" }}>
                                                    {channel}: {score}
                                                </Typography>
                                            ))}

                                            {/* Reset on Disposition Section */}
                                            <Typography variant="subtitle1" style={{ marginTop: "10px" }}>Reset on Disposition:</Typography>
                                            {config.resetOnDisposition.map((disposition, index) => (
                                                <Typography key={disposition._id} variant="body2" style={{ marginLeft: "20px" }}>
                                                    {index + 1}. {disposition.disposition_value} 
                                                </Typography>
                                            ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                {/* Edit Button */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: "10px" }}
                                    onClick={() => handleOpenEditConfigModal(config)}
                                >
                                    Edit
                                </Button>
                            </CardContent>
                        </Card>
                    </GridItem>
                ))}
            </GridContainer>

            <Dialog open={openAddConfigModal} fullScreen={true} maxWidth="lg" onClose={handleCloseAddConfigModal}>
                <DialogTitle>Add New Configuration</DialogTitle>
                <DialogContent>
                    <LeadScoringForm onClose={handleCloseAddConfigModal} />
                </DialogContent>
                <DialogActions>
                    <Button size="sm" onClick={handleCloseAddConfigModal} color="danger">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openEditConfigModal} fullScreen={true} maxWidth="lg" onClose={handleCloseEditConfigModal}>
                <DialogTitle>Edit Configuration</DialogTitle>
                <DialogContent>
                    {selectedConfig && <LeadScoringForm config={selectedConfig} onClose={handleCloseEditConfigModal} />}
                </DialogContent>
                <DialogActions>
                    <Button size="sm" onClick={handleCloseEditConfigModal} color="danger">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default LeadScoringList;
import { API_GET, API_POST, API_PUT } from "../api.service"
import { cacheUtil } from "../../services/Common";

const API_URL = "reactions";


export const fetchReactions = async (itemType, itemId) => {
    try {
        debugger;


        const response = await API_GET(`${API_URL}/${itemType}/${itemId}`);
        return response;

    } catch (error) {
        console.error("Error fetching lead scoring configs", error);
        throw error;
    }
};

export const submitReaction = async (itemId, itemType, reactionType, reactionComment) => {
    try {
        const response = await API_POST(API_URL, {
            itemId,
            itemType,
            reaction: reactionType,
            comment: reactionComment
          });
        return response;
    } catch (error) {
        console.error("Error creating lead scoring config", error);
        throw error;
    }
};
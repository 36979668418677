import React from 'react';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { InviteeStatus } from "../../../services/api.service";


const InviteesTable = ({ tableData, sentEmail, sendApprove }) => {
    return (
        <Card>
            <CardHeader color="transparent">
                <h3>Invitees</h3>
            </CardHeader>
            <CardBody>
                <Table
                    tableHeaderColor="primary"
                    tableHead={[
                        "User",
                        "Status",
                        "Invited At",
                        "Expire On",
                        "Action",
                        "",
                    ]}
                    tableData={tableData.map(element => [
                        element.username,
                        InviteeStatus[element.status],
                        element.sent ? window.moment(element.sent).fromNow() : "NA",
                        element.expireon &&
                        window.moment(element.expireon).format("DD-MMM-YYYY HH:mm:ss"),
                        <Button
                            color="danger"
                            size="sm"
                            onClick={(e) => sentEmail(e, { to_user_id: element.to_user_id })}
                        >
                            Send
                        </Button>,
                        element.status === 2 ? (
                            <Button
                                size="sm"
                                color="success"
                                onClick={(e) => sendApprove(e, element)}
                            >
                                Approve
                            </Button>
                        ) : null,
                    ])}
                />
            </CardBody>
        </Card>
    );
};

export default InviteesTable;
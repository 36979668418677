import React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControlClassName: {
        width: '100%',
    },
}));

const ChangePhoneDialog = ({ open, onClose, selectedMember, userPhone, savePhone, setUserPhone }) => {
    const classes = useStyles();

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                Change User Phone
            </DialogTitle>
            <DialogContent>
                <span>
                    for User:{" "}
                    {selectedMember && selectedMember.username}
                </span>

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            labelText="New Phone"
                            id="NewPhone"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControlClassName,
                            }}
                            inputProps={{
                                type: "Numbers",
                                value: userPhone,
                                autoComplete: "off",
                                required: true,
                                onChange: (e) => {
                                    setUserPhone(e.target.value);
                                }
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Button
                            color="success"
                            size="sm"
                            onClick={savePhone}
                        >
                            Save
                        </Button>
                        <Button
                            color="danger"
                            size="sm"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </GridItem>
                </GridContainer>
            </DialogContent>
        </Dialog>
    );
};

export default ChangePhoneDialog;
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { useMediaQuery } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";

function CustomTable({ ...props }) {
    const {
        classes,
        tableHead,
        tableData,
        tableHeaderColor,
        styling = null,
        visibleColumns = 4, // Add visibleColumns prop
    } = props;
    const isSmallScreen = useMediaQuery("(max-width: 600px)");

    if (isSmallScreen) {
        return (
            <div className="CustomTable" style={styling ? { maxHeight: "300px", overflowY: "auto" } : {}}>
                {tableData.map((row, rowIndex) => {
                    const hasMoreColumnsThanVisible =
                        tableHead && tableHead.length > visibleColumns;

                    if (!hasMoreColumnsThanVisible) {
                        return (
                            <Card key={rowIndex} style={{ marginBottom: "10px" }}>
                                <CardContent>
                                    {tableHead &&
                                        tableHead.map((header, headerIndex) => (
                                            <div key={headerIndex} style={{ marginBottom: "5px" }}>
                                                <Typography variant="subtitle2" color="textPrimary">
                                                    {header}
                                                </Typography>
                                                <Typography variant="body2">{row[headerIndex]}</Typography>
                                            </div>
                                        ))}
                                </CardContent>
                            </Card>
                        );
                    }

                    const visibleItems = tableHead.slice(0, visibleColumns);
                    const remainingItems = tableHead.slice(visibleColumns);

                    return (
                        <Accordion key={rowIndex} style={{ marginBottom: "10px" }}>
                            <AccordionSummary expandIcon={
                                
                                    <ExpandMoreIcon />
                                
                            }>
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column"
                                }}>
                                    {visibleItems.map((header, headerIndex) => (
                                        <div key={headerIndex} style={{
                                            marginBottom: "5px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}>
                                            <Typography variant="subtitle2" color="textPrimary">
                                                {header}
                                            </Typography>
                                            <Typography variant="body2">
                                                {row[headerIndex]}
                                            </Typography>
                                        </div>
                                    ))}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                    }}
                                >
                                    {remainingItems.map((header, headerIndex) => (
                                        <div
                                            key={headerIndex}
                                            style={{
                                                marginBottom: "5px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography variant="subtitle2" color="textPrimary">
                                                {header}
                                            </Typography>
                                            <Typography variant="body2">
                                                {/*Crucial change: Use visibleColumns in the index */}
                                                {row[headerIndex + visibleColumns]}
                                            </Typography>
                                        </div>
                                    ))}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        );
    }

    // Rest of the component (for larger screens) remains the same
    return (
        <div
            style={styling ? { maxHeight: "300px", overflowY: "auto" } : {}}
            className={classes.tableResponsive}
        >
            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                        <TableRow>
                            {tableHead.map((prop, key) => (
                                <TableCell
                                    key={key}
                                    className={`${classes.tableHeadCell}`}
                                    style={styling}
                                >
                                    {prop}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((prop, key) => (
                        <TableRow key={key}>
                            {prop.map((prop, key) => (
                                <TableCell className={classes.tableCell} key={key}>
                                    {prop}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

CustomTable.defaultProps = {
    tableHeaderColor: "gray",
    visibleColumns: 4, // Default value for visibleColumns
};

CustomTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray",
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
    visibleColumns: PropTypes.number, // Prop type for visibleColumns
};

export default withStyles(tableStyle)(CustomTable);
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Table/Table.jsx";
import Loader from "components/Loader/Loader.jsx";
import { API_GET, API_POST, userRoles, InviteeStatus } from "../../services/api.service";
import ManageTeamCard from "./components/ManageTeamCard.jsx";
import AddMemberCard from "./components/AddMemberCard.jsx";
import InviteesTable from "./components/InviteesTable.jsx";
import {
    ChangeManagerDialog,
    ChangeRoleDialog,
    ChangePasswordDialog,
    ChangeWebPhoneDialog,
    ChangePhoneDialog,
    ChangePermissionDialog
} from "./components";

import { ButtonGroup, IconButton } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import SettingsIcon from '@material-ui/icons/Settings';
import PolicySharp from '@material-ui/icons/PolicySharp';
import MyTeamAPI from "../../services/myteam/api.js";

const useStyles = makeStyles({
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
});

const MyTeam = (props) => {
    const classes = useStyles();
    const [errors, setErrors] = useState({});
    const [user, setUser] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [notify, setNotify] = useState({
        open: false,
        message: "",
        color: "success",
    });
    const [avbUserCount, setAvbUserCount] = useState(0);
    const [openchangemanager, setOpenChangeManager] = useState(false);
    const [openchangerole, setOpenChangeRole] = useState(false);
    const [openchangepassword, setOpenChangePassword] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);
    const [openchangewebphone, setOpenChangeWebPhone] = useState(false);
    const [newPassword, setNewPassword] = useState(null);
    const [IsWebPhone, setIsWebPhone] = useState(false);
    const [empId, setEmpId] = useState(null);
    const [agentMobile, setAgentMobile] = useState(null);
    const [callType, setCallType] = useState(null);
    const [first_name, setFirstName] = useState(null);
    const [setting, setSetting] = useState(null);
    const [activeTab, setActiveTab] = useState(null);
    const [openPhone, setOpenPhone] = useState(false);
    const [openPermission, setOpenPermission] = useState(false);
    const [userPhone, setUserPhone] = useState(null);
    const [selectedPersona, setSelectedPersona] = useState(null);
    const [persona, setPersona] = useState(null);


    const addMember = async (e) => {
        e.preventDefault();

        const fields = ["name", "email", "admin_id"];
        const formElements = e.target.elements;
        const formValues = fields
            .map((field) => ({
                [field]: formElements.namedItem(field).value.trim(),
            }))
            .reduce((current, next) => ({ ...current, ...next }));

        let registerRequest;
        try {
            registerRequest = await API_POST("register/addMember", formValues);

            if (registerRequest && registerRequest.success) {
                setNotify({
                    open: true,
                    message: registerRequest.messages.success,
                    color: "success",
                });
                GetAllMembersByUserId(user.id);
                document.getElementById("frmAddMember").reset();
            } else {
                setNotify({
                    open: true,
                    message: registerRequest.messages.success,
                    color: "danger",
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const promoteMember = async (e, element) => {
        e.preventDefault();
        if (!window.confirm("Are you sure! you wanna promote " + selectedMember.username)) {
            return;
        }
        try {
            debugger;
            let registerRequest = await MyTeamAPI.changeRole(selectedMember.user_id, element);
            if (registerRequest && registerRequest.success) {
                setNotify({
                    open: true,
                    message: "User role changed successfully.",
                    color: "success",
                });
                setOpenChangeRole(false);
                GetAllMembersByUserId(user.id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            let getSessionRequest;
            getSessionRequest = await API_GET(`get-session`);
            if (getSessionRequest && getSessionRequest.success) {
                setUser(getSessionRequest.userInfo);
                GetAllMembersByUserId(getSessionRequest.userInfo.id);
                getSetting();
            }
        };

        fetchData();
    }, []);

    const GetAllMembersByUserId = useCallback(async (userid) => {
        try {
            const response = await MyTeamAPI.getAllMembersByUserId(userid);
            if (response && response.success) {
                setUserInfo(response.data);
                if (response.data.avbUserCount) {
                    setAvbUserCount(response.data.avbUserCount);
                }
            } else {
                setUserInfo(null);
            }
        } catch (error) {
            console.error("Error in GetAllMembersByUserId:", error);
            // Handle error appropriately, maybe set an error state
        }
    }, []);

    const handleListItemClick = async (e, manager) => {
        try {
            let registerRequest = await MyTeamAPI.changeManager(selectedMember.user_id, manager.user_id);

            if (registerRequest && registerRequest.success) {
                setNotify({
                    open: true,
                    message: "Manager changed.",
                    color: "success",
                });
                GetAllMembersByUserId(user.id);
            }
        } catch (error) {
            console.log(error);
        }
        handleClose();
    };

    const handleClose = () => {
        setOpenChangeManager(false);
    };

    const changeManager = (e, element) => {
        e.preventDefault();
        setSelectedMember(element);
        setOpenChangeManager(true);
    };

    const changeRole = (e, element) => {
        e.preventDefault();
        setSelectedMember(element);
        setOpenChangeRole(true);
    };

    const changePassword = (e, element) => {
        e.preventDefault();
        setSelectedMember(element);
        setFirstName(element.first_name);
        setOpenChangePassword(true);
    };

    const changePhone = (e, element) => {
        e.preventDefault();
        setSelectedMember(element);
        setUserPhone(element.phone);
        setOpenPhone(true);
    };

    const changePermission = async (e, element) => {
        e.preventDefault();
        try {
            let request = await MyTeamAPI.getPermissions(element.id);

            setSelectedMember(element);
            setSelectedPersona(request.data.data && request.data.data.length && request.data.data[0].personaId);
            setPersona(request.data.persona);
            setOpenPermission(true);
        } catch (error) {
            console.log(error);
        }
    };

    const changeWebPhone = (e, element) => {
        e.preventDefault();

        setSelectedMember(element);
        setAgentMobile(element.agentMobile);
        setIsWebPhone(element.IsWebPhone);
        setCallType(element.callType);
        setEmpId(element.empId);
        setOpenChangeWebPhone(true);
    };

    const savePermission = async (e) => {
        try {
            let registerRequest = await MyTeamAPI.updatePermissions(selectedPersona, selectedMember);

            setOpenPermission(false);
            setNotify({
                open: true,
                message: "Data Updated",
                color: "success",
            });

        } catch (error) {
            console.log(error);
        }
    };

    const sentEmail = async (e, element) => {
        e.preventDefault();
        try {
            let request = await MyTeamAPI.sendCode(element.to_user_id);
            if (request && request.success) {
                setNotify({
                    open: true,
                    message: request.messages.success,
                    color: "success",
                });
                GetAllMembersByUserId(user.id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const saveWebPhone = async (e) => {
        saveWebPhoneInDb();
    };

    const saveWebPhoneInDb = async () => {
        try {
            let webPhoneRequest = await MyTeamAPI.updateWebPhone(IsWebPhone, empId, agentMobile, callType, selectedMember.id);

            if (webPhoneRequest && webPhoneRequest.data.update) {
                setOpenChangeWebPhone(false);
                setNotify({
                    open: true,
                    message: "Data Updated",
                    color: "success",
                });
                GetAllMembersByUserId(user.id);
            } else if (webPhoneRequest && webPhoneRequest.data.error) {
                setNotify({
                    open: true,
                    message: webPhoneRequest.data.msg,
                    color: "danger",
                });
            } else {
                setNotify({
                    open: true,
                    message: "Something went wrong",
                    color: "danger",
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const savePhone = async (e) => {
        savePhoneInDB(userPhone);
    };

    const savePassword = async (e) => {
        savePasswordInDB(newPassword);
    };

    const savePhoneInDB = async (phone) => {
        try {
            let registerRequest = await MyTeamAPI.resetPhone(phone, selectedMember.id);

            if (registerRequest && registerRequest.data.update) {
                setOpenPhone(false);
                setNotify({
                    open: true,
                    message: "Phone Changed",
                    color: "success",
                });
            } else {
                setOpenPhone(false);
                setNotify({
                    open: true,
                    message: "Something went wrong",
                    color: "danger",
                });
            }
            GetAllMembersByUserId(user.id);
        } catch (error) {
            console.log(error);
        }
    };

    const savePasswordInDB = async (password) => {
        try {
            let registerRequest = await MyTeamAPI.resetPassword(password, first_name, selectedMember.username);

            if (registerRequest && registerRequest.data.update) {
                setOpenChangePassword(false);
                setNotify({
                    open: true,
                    message: "Record Updated",
                    color: "success",
                });
                GetAllMembersByUserId(user.id);
            } else {
                setOpenChangePassword(false);
                setNotify({
                    open: true,
                    message: "Something went wrong",
                    color: "danger",
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const sendApprove = async (e, element) => {
        e.preventDefault();
        try {
            let request = await MyTeamAPI.verify(element.code);
            if (request && request.success) {
                setNotify({
                    open: true,
                    message: request.messages.success,
                    color: "success",
                });
                GetAllMembersByUserId(user.id);
            } else {
                setNotify({
                    open: true,
                    message: request.messages.error,
                    color: "danger",
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const changeUserType = async (e, element, Type) => {
        let IsRemove = true;
        if (Type === 0) {
            IsRemove = await ChkAssignedLead(element.username);
        }
        if (IsRemove) {
            try {
                let registerRequest = await MyTeamAPI.changeUserState(Type, element.user_id, element.username);
                if (registerRequest && registerRequest.data.update) {
                    setNotify({
                        open: true,
                        message: "Save Successfully",
                        color: "success",
                    });
                    GetAllMembersByUserId(user.id);
                } else {
                    setNotify({
                        open: true,
                        message: "Your user limit is over.",
                        color: "danger",
                    });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            setNotify({
                open: true,
                message: "Please change the assignment before remove member.",
                color: "danger",
            });
        }
    };

    const ChkAssignedLead = async (username) => {
        try {
            let Request = await MyTeamAPI.getCount(username);
            if (Request && Request.status === 200 && Request.data > 0) {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.log(error);
            return true; // Assume it's safe to remove if there's an error
        }
    };

    const getSetting = async () => {
        try {
            let Request = await MyTeamAPI.getData(user && user.admin);

            let settings = {};
            if (Request && Request.data) {
                for (let i = 0; i < Request.data.length; i++) {
                    settings[Request.data[i].name] = Request.data[i];
                }
            }

            setSetting(settings);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const changePermissionAction = (e) => {
        setSelectedPersona(e.target.value);
    };

    const setSettings = async (e, value) => {
        try {
            await MyTeamAPI.upsert(value, user && user.id, user && user.admin);
            await getSetting();
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const managerslist = userInfo && userInfo.teams_membership
        ? userInfo.teams_membership
            .filter(element => element.role === "owner" || element.role === "manager")
            .map(element => ({
                user_id: element.user_id,
                username: element.username,
            }))
        : [];

    const managers = userInfo && userInfo.teams_membership
        ? userInfo.teams_membership
            .filter(element => element.role === "manager")
            .map(element => element.username)
        : [];

    const tableData = userInfo && userInfo.teams_membership
        ? userInfo.teams_membership
            .filter(element => element.is_staff === 1)
            .map(element => [
                element.username,
                element.first_name,
                element.phone,
                element.password,
                userRoles[element.role],
                <a onClick={(e) => changeManager(e, element)}>
                    {element.Manager}
                </a>,
                element.last_login
                    ? window.moment(element.last_login).fromNow()
                    : "NA",
                element.last_activity
                    ? window.moment(element.last_activity).fromNow()
                    : "NA",
                element.expireon
                    ? window.moment(element.expireon).format('DD-MMM-YYYY')
                    : "NA",

                element.last_Recording_Sync
                    ? window.moment(element.last_Recording_Sync).format('DD-MMM-YYYY')
                    : "NA",

                element.last_CallDetails_Sync
                    ? window.moment(element.last_CallDetails_Sync).format('DD-MMM-YYYY')
                    : "NA",
                <ButtonGroup>
                    {element.is_active && element.is_active === 1 ? (
                        <IconButton
                            color="success"
                            title="Active"
                            size="small"
                            onClick={(e) => changeUserType(e, element, 0)}
                        >
                            <VisibilityIcon fontSize="small" />
                        </IconButton>
                    ) : (
                        <IconButton
                            color="error"
                            title="De-Active"
                            size="small"
                            onClick={(e) => changeUserType(e, element, 1)}
                        >
                            <VisibilityOffIcon fontSize="small" />
                        </IconButton>
                    )}

                    <IconButton
                        title="Change Role"
                        size="small"
                        onClick={(e) => changeRole(e, element)}
                    >
                        <VerifiedUserIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                        title="Change Password"
                        size="small"
                        onClick={(e) => changePassword(e, element)}
                    >
                        <VpnKeyIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                        title="Configuration"
                        size="small"
                        onClick={(e) => changeWebPhone(e, element)}
                    >
                        <SettingsIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                        title="Change Phone No."
                        size="small"
                        onClick={(e) => changePhone(e, element)}
                    >
                        <PhoneCallbackIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                        title="Permissions"
                        size="small"
                        onClick={(e) => changePermission(e, element)}
                    >
                        <PolicySharp fontSize="small" />
                    </IconButton>
                </ButtonGroup>
            ])
        : [];

    

    return (
        <div>
            <ChangeManagerDialog
                open={openchangemanager}
                handleClose={handleClose}
                managerslist={managerslist}
                handleListItemClick={handleListItemClick}
            />

            <ChangeRoleDialog
                open={openchangerole}
                onClose={() => setOpenChangeRole(false)}
                
                promoteMember={promoteMember}
            />

            <ChangeWebPhoneDialog
                open={openchangewebphone}
                onClose={() => setOpenChangeWebPhone(false)}
                selectedMember={selectedMember}
                IsWebPhone={IsWebPhone}
                empId={empId}
                agentMobile={agentMobile}
                callType={callType}
                saveWebPhone={saveWebPhone}
                setIsWebPhone={setIsWebPhone}
                setEmpId={setEmpId}
                setAgentMobile={setAgentMobile}
                setCallType={setCallType}
            />

            <ChangePasswordDialog
                open={openchangepassword}
                onClose={() => setOpenChangePassword(false)}
                selectedMember={selectedMember}
                first_name={first_name}
                newPassword={newPassword}
                savePassword={savePassword}
                setFirstName={setFirstName}
                setNewPassword={setNewPassword}
            />

            <ChangePermissionDialog
                open={openPermission}
                onClose={() => setOpenPermission(false)}
                selectedMember={selectedMember}
                selectedPersona={selectedPersona}
                persona={persona}
                savePermission={savePermission}
                changePermissionAction={changePermissionAction}
                setSelectedPersona={setSelectedPersona}
            />

            <ChangePhoneDialog
                open={openPhone}
                onClose={() => setOpenPhone(false)}
                selectedMember={selectedMember}
                userPhone={userPhone}
                savePhone={savePhone}
                setUserPhone={setUserPhone}
            />

            <Snackbar
                place="tr"
                color={notify.color}
                message={notify.message}
                open={notify.open}
                closeNotification={() =>
                    setNotify({
                        open: false,
                        message: "",
                        color: "success",
                    })
                }
                close
            />
            <GridContainer>
                <GridItem
                    xs={12}
                    sm={12}
                    md={avbUserCount > 0 ? 6 : 12}
                >
                    <ManageTeamCard
                        classes={classes}
                        userInfo={userInfo}
                        user={user}
                        managers={managers}
                        history={props.history}
                    />
                </GridItem>

                {avbUserCount > 0 && (
                    <GridItem xs={12} sm={12} md={6}>
                        <AddMemberCard
                            classes={classes}
                            errors={errors}
                            name={props.name}
                            email={props.email}
                            user={user}
                            addMember={addMember}
                        />
                    </GridItem>
                )}

                {openPermission && <ChangePermissionDialog
                    open={openPermission}
                    onClose={() => setOpenPermission(false)}
                    selectedMember={selectedMember}
                    selectedPersona={selectedPersona}
                    persona={persona}
                    savePermission={savePermission}
                    changePermissionAction={changePermissionAction}
                    setSelectedPersona={setSelectedPersona}
                />}

                {userInfo && userInfo.invitees.length > 0 && (
                    <GridItem xs={12} sm={12} md={12}>
                        <InviteesTable
                            tableData={userInfo && userInfo.invitees}
                            sentEmail={sentEmail}
                            sendApprove={sendApprove}
                        />
                    </GridItem>
                )}

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="transparent">
                            <h3>Members</h3>
                        </CardHeader>
                        <CardBody style={{ minHeight: '200px' }}>
                            {!userInfo ? (
                                <Loader />
                            ) : (
                                <Table
                                    tableHeaderColor=""
                                    tableHead={[
                                        "User",
                                        "name",
                                        "phone",
                                        "Password",
                                        "Role",
                                        "Manager",
                                        "Last Login",
                                        "Last Activity",
                                        "expireOn",
                                        "Recording Sync",
                                        "Call Detail Sync",
                                        "Action",
                                        "",
                                        "",
                                    ]}
                                    tableData={tableData}
                                />
                            )}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default MyTeam;
import { API_GET, API_POST, API_PUT } from "../api.service"
import { cacheUtil } from "../../services/Common";

const API_URL = "lead-scoring";


export const getLeadScoringConfigs = async () => {
    try {
        debugger;
        const response = await API_GET(`${API_URL}`);
        return response;
    } catch (error) {
        console.error("Error fetching lead scoring configs", error);
        throw error;
    }
};

export const createLeadScoringConfig = async (data) => {
    try {
        const response = await API_POST(API_URL, data);
        return response.data;
    } catch (error) {
        console.error("Error creating lead scoring config", error);
        throw error;
    }
};

export const updateLeadScoringConfig = async (id, data) => {
    try {
        const response = await API_PUT(`${API_URL}/${id}`, data);
        return response.data;
    } catch (error) {
        console.error("Error updating lead scoring config", error);
        throw error;
    }
};

export const executeLeadScoring = async (id, data) => {
    try {
        const response = await API_POST(`${API_URL}/execute/${id}`, data);
        return response.data;
    } catch (error) {
        console.error("Error executing lead scoring", error);
        throw error;
    }
};

export const getProspectfields = async () => {
    let registerRequest;
    try {
        if (cacheUtil.get("prospect_fields")) {
            registerRequest = cacheUtil.get("prospect_fields");
        }
        else {
            registerRequest = await API_POST("action/getdata", {
                root: "prospect_fields",
                cols: {},
            });
        }
        return registerRequest.data;
    } catch (error) {
        console.error("Error executing lead scoring", error);
        throw error;
    }
};
import React, { useEffect, useState } from "react";
import { TextField, Select, MenuItem, IconButton, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DispositionSelector from "./DispositionSelector";
import { getProspectfields } from "../../services/leadscoring/api";



const RuleRow = ({ rule, onRuleChange, onRemoveRule }) => {
    const [disabled, setDisabled] = useState(false);
    const [prospect_fields, setProspectFields] = useState([]);

    useEffect(async () => {
        if (["disposition", "updateStatus"].indexOf(rule.field) > -1) {
            setDisabled(true);
        }
        const prospectfileds = await getProspectfields();
        console.log("prospectfileds", prospectfileds)
        setProspectFields(prospectfileds);
    }, [rule]);

    const onSelectDisposition = (rule, items) => {
        debugger;


        const target = {
            name: "value",
            value: [...rule.value, ...(items.length > 1 ? [items[items.length - 1]] : [items[0]])]
        };
        onRuleChange({ target });
    };

    return (
        <Grid container spacing={2} alignItems="center">
            {/* Field */}
            <Grid item xs={12} md={3}>

                {["disposition", "talktime", "updateStatus"].indexOf(rule.field) > -1 && (
                    <TextField
                        label="Field"
                        name="field"
                        disabled={disabled}
                        value={rule.field}
                        onChange={onRuleChange}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                )}
                {["disposition", "talktime", "updateStatus"].indexOf(rule.field) === -1 && (
                    <Select
                        label="Value"
                        name="value"
                        value={rule.Value}
                        onChange={onRuleChange}
                        fullWidth
                        variant="outlined"
                        size="small"
                    >

                        {prospect_fields && prospect_fields.map((field) => (
                            <MenuItem value={field.field_name}>{field.display_Name}</MenuItem>
                        ))}



                    </Select>
                )}



            </Grid>

            {/* Operator */}
            <Grid item xs={12} md={2}>
                <Select
                    label="Operator"
                    name="operator"
                    disabled={disabled}
                    value={rule.operator}
                    onChange={onRuleChange}
                    fullWidth
                    variant="outlined"
                    size="small"
                >
                    <MenuItem value="==">Equals</MenuItem>
                    <MenuItem value="!=">Not Equals</MenuItem>
                    <MenuItem value=">">Greater Than</MenuItem>
                    <MenuItem value="<">Less Than</MenuItem>
                    <MenuItem value=">=">Greater Than or Equal</MenuItem>
                    <MenuItem value="<=">Less Than or Equal</MenuItem>
                </Select>
            </Grid>

            {/* Value */}
            <Grid item xs={12} md={3}>
                
                {rule.field === "disposition" && (
                    <DispositionSelector disposition={rule.value} onSelectDisposition={(items) => onSelectDisposition(rule, items)} />
                )}

                {rule.field === "talktime" && (
                    <TextField
                        label="Talktime(In Seconds)"
                        name="value"
                        value={rule.value}
                        onChange={onRuleChange}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                )}

                {rule.field === "updateStatus" && (
                    <Select
                        label="Value"
                        name="value"
                        value={rule.value}
                        onChange={onRuleChange}
                        fullWidth
                        variant="outlined"
                        size="small"
                    >
                        <MenuItem value="inserted">Inserted</MenuItem>
                        <MenuItem value="updated">Updated</MenuItem>
                    </Select>
                )}

                {["disposition", "talktime", "updateStatus"].indexOf(rule.field) === -1 && (

                    <TextField
                        label="Value"
                        name="value"
                        value={rule.value}
                        onChange={onRuleChange}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                )}
            </Grid>

            {/* Score */}
            <Grid item xs={12} md={2}>
                <TextField
                    label="Score"
                    name="score"
                    type="number"
                    value={rule.score}
                    onChange={onRuleChange}
                    fullWidth
                    variant="outlined"
                    size="small"
                />
            </Grid>

            {/* Delete Button */}
            <Grid item xs={12} md={1}>
                <IconButton onClick={onRemoveRule} size="small">
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default RuleRow;
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import TextField from "@material-ui/core/TextField";
import { API_POST } from "../../services/api.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { FormControl } from "@material-ui/core";


function CountryMaster(props) {
  const {} = props;
  let [InputType, setInputType] = useState("");
  const [Country, setCountry] = useState([]);

  const getCountryMaster = async () => {
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "CountryMaster",
        con: {  },
      });

      if (registerRequest && registerRequest.status == 200) {



        for(let i=0;i<registerRequest.data.length;i++){
          let e=registerRequest.data[i];

          if(e.CountryName=='India'){
            handleChange(e,e);
          }
        }
        setCountry(registerRequest.data);

      }
    } catch (error) {}
  };

  useEffect(() => {
    getCountryMaster();
  }, []);

  const handleChange = (event, value) => {
    debugger;
    setInputType(value);

    props.setCallCountry(value);
  };

  return (
    <>
     <FormControl   fullWidth= {true}>
      <Autocomplete
        onChange={handleChange}
        id="combo-box-demo"
        size
        options={Country}
        labelText="FieldType"
        name="autoComplete"
        value={InputType || null}
        getOptionLabel={(Country) => Country.CountryName || ""}
        defaultValue="Select"
        renderTags={(value) =>
          value.map(() => <Chip variant="outlined" label="Enter Field" />)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Country "
            // variant="outlined"
           
          />
        )}
      />
      <input type="hidden" name="country"  value={InputType && InputType.ISO2}/>
      <input type="hidden" name="ISO3"  value={InputType && InputType.ISO3}/>
      </FormControl>
    </>
  );
}

CountryMaster.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default CountryMaster;

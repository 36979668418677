/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import { CardActions } from "@material-ui/core";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert"; // Unused import, will remove
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Loader from "components/Loader/Loader.jsx";
import InfoBox from "components/InfoIcon/InfoIcon.jsx";
import { API_POST } from "../../services/api.service";
import { getLeadScoringConfigs } from "services/leadscoring/api.js"


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const defaultCampaignData = { // Moved default data outside component for better practice
  name: "",
  workflow: "",
  telephony: "Default",
  leadscoring: "67b46491b26a08e8fe735ce2",
  viewType: "",
  prospectFilter: "",
  helpScript: "",
  launchTime: "",
  disposition: [],
  formFields: [],
  callImmediately: false,
  mandatoryRemarks: false,
  enableAgentFilter: false,
  phoneNumberMasking: false,
  assignFollowUps: "",
  scheduleAppointment: false,
  minimumSlotDuration: 0,
  enableMessageOnDisposition: false,
  enableEditMessage: false,
  enableDeleteProspect: false,
  ActiveInactiveProspect: false,
  IsActive: false,
  communication_type: null,
  templateId: null,
  enable_message: false,
  createdOn: null,
  updatedOn: null,
  admin: null, // admin will be set dynamically
};


const ManageCampaigns = (props) => { // Converted to functional component
  const { classes, user, history, match } = props;
  const { id } = match.params;
  const mode = id && match.url.indexOf("/admin/editcampaigns") > -1 ? "Edit" : "Add";

  const [campaignsDetails, setCampaignsDetails] = useState({ // Using useState hook
    ...defaultCampaignData,
    admin: user ? user.admin : undefined, // Set admin here dynamically - Optional chaining removed
  });
  const [customFormFields, setCustomFormFields] = useState([]);
  const [dispositions, setDispositions] = useState([]);
  const [telephony, setTelephony] = useState([]);
  const [showLoader, setShowLoader] = useState(mode === "Edit"); // Loader only initially for edit mode
  const [templates, setTemplates] = useState([]);
  const [leadscoring, setLeadscoring] = useState([]);
  
  const [notify, setNotify] = useState({
    open: false,
    message: "",
    color: "success",
  });

  const fetchleadscoring = async () => {
          try {
              const data = await getLeadScoringConfigs();
              debugger;
              if(data){
                const options = [{ key: "Default", value: "BeTyphon AI Agent" }, ...data.map(element => ({ // Spread initial default option
                  key: element._id,
                  value: element.name,
                }))];
                setLeadscoring(options);
              }
              
          } catch (error) {
              console.error("Error fetching configurations", error);
          }
      };

  const fetchTemplates = useCallback(async (type) => { // Using useCallback for memoization
    if (type === "none") {
      setTemplates([]); // Clear templates when type is none
      return;
    }
    try {
      const data = await API_POST("action/getdata", {
        root: "Templates",
        con: { admin: user && user.admin, isActive: true, type: type },
        cols: {},
      });

      if (data && data.status === 200 && data && data.data) { // Optional chaining and null check - already compatible with es2015
        const templateOptions = data.data.map(element => ({ // More concise mapping
          key: element._id,
          value: element.name,
          ...element, // Spread operator to include other element properties if needed
        }));
        setTemplates(templateOptions);
      }
    } catch (error) {
      console.error("Error fetching templates:", error); // More informative error logging
      setNotify({ open: true, message: "Error fetching templates", color: "danger" }); // User feedback for errors
    }
  }, [user]);


  const handleChange = useCallback((e) => { // Using useCallback for memoization
    let formValue = { ...campaignsDetails }; // Create a copy to avoid direct state mutation

    if (e.target.type === "checkbox") {
      formValue[e.target.id] = e.target.checked;
    } else {
      formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
    }

    if (e.target.name === 'communication_type') {
      fetchTemplates(e.target.value);
    }

    setCampaignsDetails(formValue);
  }, [campaignsDetails, fetchTemplates]); // Dependencies for useCallback


  const loadCustomFields = useCallback(async () => { // Using useCallback for memoization
    try {
      const registerRequest = await API_POST("action/getdata", {
        root: "custom_forms_new",
        con: { admin: user && user.admin },
        cols: {},
      });

      if (registerRequest && registerRequest.status === 200 && registerRequest.data) { // Optional chaining and null check - already compatible with es2015
        const options = registerRequest.data.map((element) => ({
          key: element._id,
          value: element.custom_field_name,
        }));
        setCustomFormFields(options);
      }
    } catch (error) {
      console.error("Error loading custom fields:", error); // More informative error logging
      setNotify({ open: true, message: "Error loading custom fields", color: "danger" }); // User feedback for errors
    }
  }, [user]);

  const loadDispositions = useCallback(async () => { // Using useCallback for memoization
    try {
      const registerRequest = await API_POST("action/getdata", {
        root: "dispositions",
        con: { admin: user && user.admin,IsActive:{$ne: false} },
        cols: {},
      });

      if (registerRequest && registerRequest.status === 200 && registerRequest.data) { // Optional chaining and null check - already compatible with es2015
        const options = registerRequest.data
          .filter(element => element.parentId === null) // Filtering directly in map
          .map(element => ({
            key: element._id,
            value: element.disposition_value,
          }));

        setDispositions(options);
      }
    } catch (error) {
      console.error("Error loading dispositions:", error); // More informative error logging
      setNotify({ open: true, message: "Error loading dispositions", color: "danger" }); // User feedback for errors
    }
  }, [user]);

  const loadCampaign = useCallback(async (campaignId) => { // Using useCallback for memoization
    try {
      const registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { _id: campaignId, admin: user && user.admin },
        cols: {},
      });

      if (registerRequest && registerRequest.status === 200 && registerRequest.data && registerRequest.data.length > 0) { // Optional chaining and null check - already compatible with es2015
        const campaignData = registerRequest.data[0];
        fetchTemplates(campaignData.communication_type); // Fetch templates based on campaign type
        setCampaignsDetails(campaignData);
      } else if (mode === "Edit") {
        setNotify({ open: true, message: "Campaign not found", color: "warning" }); // User feedback if campaign not found in edit mode
      }
    } catch (error) {
      console.error("Error loading campaign:", error); // More informative error logging
      setNotify({ open: true, message: "Error loading campaign details", color: "danger" }); // User feedback for errors
    } finally {
      setShowLoader(false); // Hide loader after attempt, regardless of success or failure
    }
  }, [user, fetchTemplates, mode]);


  const localCallingSetUp = useCallback(async () => { // Using useCallback for memoization
    try {
      const registerRequest = await API_POST("action/getdata", {
        root: "CallingSetup",
        con: { admin: user && user.admin, IsActive: true, type: 'CallingAPI' },
        cols: {},
      });

      if (registerRequest && registerRequest.status === 200 && registerRequest.data) { // Optional chaining and null check - already compatible with es2015
        const options = [{ key: "Default", value: "Default" }, ...registerRequest.data.map(element => ({ // Spread initial default option
          key: element._id,
          value: element.name,
        }))];
        setTelephony(options);
      }
    } catch (error) {
      console.error("Error setting up local calling:", error); // More informative error logging
      setNotify({ open: true, message: "Error loading telephony options", color: "danger" }); // User feedback for errors
    }
  }, [user]);


  useEffect(() => { // useEffect for initial data loading
    loadCustomFields();
    loadDispositions();
    localCallingSetUp();
    fetchleadscoring();
    if (mode === "Edit") {
      loadCampaign(id);
    } else {
      setShowLoader(false); // Hide loader for add mode immediately
    }
  }, [loadCustomFields, loadDispositions, localCallingSetUp, loadCampaign, mode, id]); // Dependencies for useEffect


  const handleCancel = () => {
    history.push("/admin/campaigns");
  };


  const handleSaveCampaign = async () => {
    try {
      setShowLoader(true); // Show loader during save operation
      const formValue = { ...campaignsDetails }; // Create copy to avoid direct mutation
      formValue.admin = user && user.admin;
      formValue.updatedOn = new Date();

      let apiAction = mode === "Edit" ? "update" : "addRecord";
      let apiRoot = "campaigns";
      let apiQueryData = mode === "Edit" ? { _id: id } : {};
      if (mode !== "Edit") {
        formValue.createdBy = user && user.username;
        formValue.createdOn = new Date();
        if (["manager"].includes(user && user.role)) { // More concise includes check
          formValue.campaignMembers = [user && user.username];
        }

        const checkDuplicate = await API_POST("action/getdata", { // Check for duplicate name before adding
          root: "campaigns",
          con: { admin: user && user.admin, name: formValue.name },
          cols: {},
        });
        if ( checkDuplicate && checkDuplicate.data && checkDuplicate.data.length > 0) {
          setNotify({ open: true, message: "Duplicate Campaign Name Found", color: "danger" });
          setShowLoader(false);
          return; // Exit if duplicate found
        }
      }


      const registerRequest = await API_POST(`action/${apiAction}`, {
        root: apiRoot,
        body: formValue,
        querydata: apiQueryData,
      });


      if (registerRequest && registerRequest.status === 200) {
        if (mode !== "Edit" && ["manager"].includes(user && user.role)) { // Handle campaign members for managers on add
          await API_POST("action/upsert", {
            root: "campaignMembers",
            body: {
              role: user.role,
              createdOn: new Date(),
              createdBy: user.username,
              updatedOn: new Date(),
              updatedBy: user.username,
              IsActive: true,
            },
            querydata: { cid: registerRequest.data, username: user.username, admin: user.admin },
          });
        }
        history.push("/admin/campaigns"); // Redirect on successful save
      } else {
        setNotify({ open: true, message: "Error saving campaign", color: "danger" }); // User feedback for save errors
      }
    } catch (error) {
      console.error("Error saving campaign:", error); // More informative error logging
      setNotify({ open: true, message: "Error saving campaign", color: "danger" }); // User feedback for save errors
    } finally {
      setShowLoader(false); // Hide loader after save attempt
    }
  };


  return (
    <>
      <Card>
        <Snackbar
          place="tr"
          color={notify.color}
          message={notify.message}
          open={notify.open}
          closeNotification={() => setNotify({ ...notify, open: false })} // More concise close notification
          close
        />

        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{mode} Campaigns</h4>
          <p className={classes.cardCategoryWhite}>Manage your campaigns</p>
        </CardHeader>
        <CardBody>
          {showLoader && <Loader />} {/* Conditional rendering of Loader */}
          {!showLoader && (
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CampaignDetailsSection
                  campaignsDetails={campaignsDetails}
                  handleChange={handleChange}
                  telephony={telephony}
                  leadscoring={leadscoring}
                  templates={templates}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={6}>
                <CampaignSettingsSection
                  campaignsDetails={campaignsDetails}
                  handleChange={handleChange}
                  dispositions={dispositions}
                  customFormFields={customFormFields}
                />
              </GridItem>
            </GridContainer>
          )}
        </CardBody>
        {!showLoader && (
          <CardActions>
            <Button color="primary" size="sm" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="primary" size="sm" onClick={handleSaveCampaign}>
              Save
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  );
};


const CampaignDetailsSection = ({ campaignsDetails, handleChange, leadscoring, telephony, templates }) => ( // Extracted CampaignDetails section
  <>
    <>
      <h3>Campaign Details
        <GridItem style={{ float: "right", marginTop: "10px" }}>
          <InfoBox name={'Campaigns.AddCampaigns'} />
        </GridItem>
      </h3>
    </>
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Name *"
            id="name"
            labelProps={{ shrink: true }}
            formControlProps={{ fullWidth: true, multiline: true }}
            inputProps={{
              required: true,
              value: campaignsDetails.name,
              name: "name",
              onChange: handleChange,
            }}
          />
        </GridItem>

        <GenerateField
          value={campaignsDetails.workflow}
          onChange={handleChange}
          id={"workflow"}
          label={"Work Flow"}
          options={"None,Lead Generation,Custom"}
        />
        
        <GenerateField
          value={campaignsDetails.telephony}
          onChange={handleChange}
          id={"telephony"}
          label={"Telephony"}
          keyValuePair={telephony}
        />
        
        {/* <GenerateField
          value={campaignsDetails.leadscoring}
          onChange={handleChange}
          id={"leadscoring"}
          label={"Lead Scoring"}
          keyValuePair={leadscoring}
        /> */}

        <GenerateField
          value={campaignsDetails.viewType}
          onChange={handleChange}
          id={"viewType"}
          label={"View Type"}
          options={"None,Cycle Based,Sheet Based"}
        />
        <GenerateField
          value={campaignsDetails.prospectFilter}
          onChange={handleChange}
          id={"prospectFilter"}
          label={"Prospect Filter"}
          options={"None"}
        />
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Help Script"
            id="helpScript"
            labelProps={{ shrink: true }}
            formControlProps={{ fullWidth: true, multiline: true }}
            inputProps={{
              required: false,
              value: campaignsDetails.helpScript,
              name: "helpScript",
              type: "textarea",
              onChange: handleChange,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Launch Time"
            id="launchTime"
            labelProps={{ shrink: true }}
            formControlProps={{ fullWidth: true, multiline: true }}
            inputProps={{
              required: false,
              value: campaignsDetails.launchTime,
              defaultValue: campaignsDetails.launchTime,
              name: "launchTime",
              type: "date_time",
              onChange: handleChange,
            }}
          />
        </GridItem>


        <>
          <GridItem xs={12} sm={12} md={12} pt={20}>

            <GenerateField
              value={campaignsDetails.enable_message}
              onChange={handleChange}
              id={"enable_message"}
              label={"Enable Welcome Message on new Integration Prospect"}
              type="checkbox"
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={12} pt={20}>

            <GenerateField
              value={campaignsDetails.enable_template_whatsapp === false ? false : true}
              onChange={handleChange}
              id={"enable_template_whatsapp"}
              label={"Enable Template Option(whatsapp)"}
              type="checkbox"
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={12} pt={20}>

            <GenerateField
              value={campaignsDetails.enable_template_email === false ? false : true}
              onChange={handleChange}
              id={"enable_template_email"}
              label={"Enable Template Option(email)"}
              type="checkbox"
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={12} pt={20}>

            <GenerateField
              value={campaignsDetails.enable_template_sms === false ? false : true}
              onChange={handleChange}
              id={"enable_template_sms"}
              label={"Enable Template Option(sms)"}
              type="checkbox"
            />
          </GridItem>


          {campaignsDetails.enable_message &&
            (<>

              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Select Communication Type"
                  id="communication_type"
                  formControlProps={{ fullWidth: true }}
                  labelProps={{ shrink: true }}
                  inputProps={{
                    required: false,
                    name: "communication_type",
                    type: "select",
                    value: campaignsDetails.communication_type,
                    onChange: handleChange,
                    options: [
                      { key: "none", value: "No Template" },
                      { key: "email", value: "Email Template" },
                      { key: "sms", value: "SMS Template" },
                      { key: "whatsapp", value: "WhatsApp Template" }
                    ],
                  }}
                />
              </GridItem>

              {['', 'none'].indexOf(campaignsDetails.templateId) === -1 &&
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Select Template Type"
                    id="templateId"
                    formControlProps={{ fullWidth: true }}
                    labelProps={{ shrink: true }}
                    inputProps={{
                      required: false,
                      name: "templateId",
                      type: "select",
                      defaultValue: campaignsDetails.templateId,
                      onChange: handleChange,
                      options: templates,
                    }}
                  />
                </GridItem>
              }

            </>
            )
          }
        </>
      </GridContainer>
    </>
  </>
);


const CampaignSettingsSection = ({ campaignsDetails, handleChange, dispositions, customFormFields }) => ( // Extracted CampaignSettings section
  <>
    <>
      <h3>Campaign Settings</h3>
    </>
    <>
      <GridContainer>
        <GenerateField
          value={campaignsDetails.disposition}
          onChange={handleChange}
          id={"disposition"}
          label={"Disposition"}
          type="checkbox_list"
          keyValuePair={dispositions}
        />
        <GenerateField
          value={campaignsDetails.formFields}
          onChange={handleChange}
          id={"formFields"}
          label={"Additional Fields"}
          type="checkbox_list"
          keyValuePair={customFormFields}
        />
        <GenerateField
          value={campaignsDetails.callImmediately}
          onChange={handleChange}
          id={"callImmediately"}
          label={"Call Prospect immediately after adding manually"}
          type="checkbox"
        />

        <GenerateField
          value={campaignsDetails.mandatoryRemarks}
          onChange={handleChange}
          id={"mandatoryRemarks"}
          label={"Require remarks in response"}
          type="checkbox"
        />
        <GenerateField
          value={campaignsDetails.enableAgentFilter}
          onChange={handleChange}
          id={"enableAgentFilter"}
          label={"Enable Agent filters while calling"}
          type="checkbox"
        />
        <GenerateField
          value={campaignsDetails.phoneNumberMasking}
          onChange={handleChange}
          id={"phoneNumberMasking"}
          label={"Mask Phone numbers"}
          type="checkbox"
        />

        <GenerateField
          value={campaignsDetails.assignFollowUps}
          onChange={handleChange}
          id={"assignFollowUps"}
          label={"Assign Follow-Ups To"}
          type="select"
          keyValuePair={[
            { key: "None", value: "None" },
            { key: "Any", value: "Any available agent" },
            { key: "Past", value: "One of Past Handler" },
            { key: "Last", value: "Only Last Handler" },
          ]}
        />

        <GenerateField
          value={campaignsDetails.scheduleAppointment}
          onChange={handleChange}
          id={"scheduleAppointment"}
          label={"Schedule appointments in slots"}
          type="checkbox"
        />

        <GenerateField
          value={campaignsDetails.minimumSlotDuration}
          onChange={handleChange}
          id={"minimumSlotDuration"}
          label={"Minimum slot duration"}
          type="text"
          disabled={!campaignsDetails.scheduleAppointment}
        />

        <GenerateField
          value={campaignsDetails.enableMessageOnDisposition}
          onChange={handleChange}
          id={"enableMessageOnDisposition"}
          label={"Send Messages based on Disposition"}
          type="checkbox"
        />
        <GenerateField
          value={campaignsDetails.enableEditMessage}
          onChange={handleChange}
          id={"enableEditMessage"}
          label={"Allow to Edit Messages"}
          type="checkbox"
        />
        <GenerateField
          value={campaignsDetails.enableFreshProspect}
          onChange={handleChange}
          id={"enableFreshProspect"}
          label={"Allow to Make Prospect Fresh"}
          type="checkbox"
        />
        <GenerateField
          value={campaignsDetails.enableDeleteProspect}
          onChange={handleChange}
          id={"enableDeleteProspect"}
          label={"Allow to Delete Prospect"}
          type="checkbox"
        />

        <GenerateField
          value={campaignsDetails.enableAssignFromCallPage}
          onChange={handleChange}
          id={"enableAssignFromCallPage"}
          label={"Allow to Assign Prospect from Call Page"}
          type="checkbox"
        />

        <GenerateField
          value={campaignsDetails.ActiveInactiveProspect}
          onChange={handleChange}
          id={"ActiveInactiveProspect"}
          label={"Allow to Active/Deactive Files"}
          type="checkbox"
        />

        <GenerateField
          value={campaignsDetails.closeTagsPermission === false ? false : true}
          onChange={handleChange}
          id={"closeTagsPermission"}
          label={"Permission to remove tags to agents"}
          type="checkbox"
        />

        <GenerateField
          value={campaignsDetails.disableManualProspectCreate}
          onChange={handleChange}
          id={"disableManualProspectCreate"}
          label={"Disable Manual Prospect Creation"}
          type="checkbox"
        />


        <GenerateField
          value={campaignsDetails.requiredDisposition}
          onChange={handleChange}
          id={"requiredDisposition"}
          label={"Require atleast 1 tags to save dispositions"}
          type="checkbox"
        />




        <GenerateField
          value={campaignsDetails.enableAutoCalling}
          onChange={handleChange}
          id={"enableAutoCalling"}
          label={"Allow to Auto Call"}
          type="checkbox"
        />

        <GenerateField
          value={campaignsDetails.autoCallDuration}
          onChange={handleChange}
          id={"autoCallDuration"}
          label={"Auto Call Duration ( in Sec )"}
          disabled={!campaignsDetails.enableAutoCalling}
          type="select"
          keyValuePair={[
            { key: "5", value: "5" },
            { key: "10", value: "10" },
            { key: "15", value: "15" },
            { key: "20", value: "20" },
            { key: "25", value: "25" },
            { key: "30", value: "30" },
          ]}
        />

        <GenerateField
          value={campaignsDetails.IsActive}
          onChange={handleChange}
          id={"IsActive"}
          label={"Active"}
          type="checkbox"
        />

      </GridContainer>
    </>
  </>
);


const GenerateField = React.memo((props) => { // Using React.memo for functional component
  const {
    value,
    onChange,
    id,
    options,
    label,
    keyValuePair,
    type = "select",
    disabled = false,
  } = props;

  return (
    <GridItem xs={12} sm={12} md={12}>
      <CustomInput
        labelText={label}
        labelProps={{ shrink: true }}
        formControlProps={{
          fullWidth: true,
          multiline: true,
        }}
        customOptions={options}
        inputProps={{
          required: false,
          value,
          defaultValue: value,
          checked: value,
          name: id,
          id: id,
          type: type,
          onChange,
          disabled,
          options: keyValuePair,
        }}
      />
    </GridItem>
  );
});


export default withStyles(styles)(ManageCampaigns);
import React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControlClassName: {
        width: '100%',
    },
}));

const ChangeWebPhoneDialog = ({ open, onClose, selectedMember, IsWebPhone, empId, agentMobile, callType, saveWebPhone, setIsWebPhone, setEmpId, setAgentMobile, setCallType }) => {
    const classes = useStyles();

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                Change WebPhone
            </DialogTitle>
            <DialogContent>
                <span>
                    for User:{" "}
                    {selectedMember && selectedMember.username}
                </span>

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            labelText="Web Phone"
                            id="IsWebPhone"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControlClassName,
                            }}
                            inputProps={{
                                type: "checkbox",
                                checked: IsWebPhone,
                                required: true,
                                onChange: (e) => {
                                    setIsWebPhone(e.target.checked);
                                },
                            }}
                        />

                        <CustomInput
                            labelText="EmployeeId/ Ext/ Agent Mobile"
                            id="EmployeeId"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControlClassName,
                            }}
                            inputProps={{
                                type: "text",
                                value: empId,
                                autoComplete: "off",
                                onChange: (e) => {
                                    setEmpId(e.target.value);
                                },
                            }}
                        />

                        <CustomInput
                            labelText="Call Type"
                            id="calltype"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControlClassName,
                            }}
                            inputProps={{
                                type: "text",
                                value: callType,
                                autoComplete: "off",
                                required: true,
                                onChange: (e) => {
                                    setCallType(e.target.value);
                                },
                            }}
                        />
                        <p>If you are using the BeTyphon autodialer (Webphone or Mobile), you can continue with it. Alternatively, you can use the provider’s services as needed.</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Button
                            color="success"
                            size="sm"
                            onClick={saveWebPhone}
                        >
                            Save
                        </Button>
                        <Button
                            color="danger"
                            size="sm"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </GridItem>
                </GridContainer>
            </DialogContent>
        </Dialog>
    );
};

export default ChangeWebPhoneDialog;
import React from 'react';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Loader from "components/Loader/Loader.jsx";

const AddMemberCard = ({ classes, errors, name, email, user, addMember }) => {
    return (
        <Card>
            <CardHeader color="transparent">
                <h3>Add Team</h3>
            </CardHeader>
            <CardBody style={{ minHeight: '200px' }}>
                {!user ? (
                    <Loader />
                ) : (
                    <form onSubmit={addMember} id="frmAddMember">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Name"
                                    id="name"
                                    error={errors.name}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        required: true,
                                        defaultValue: name,
                                        name: "name",
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Email address"
                                    id="email-address"
                                    error={errors.email}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        required: true,
                                        defaultValue: email,
                                        name: "email",
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} />
                        </GridContainer>
                        <CardFooter>
                            <input
                                type="hidden"
                                id="admin_id"
                                name="admin_id"
                                value={user && user.id}
                            />
                            <Button type="submit" color="info" size="sm">
                                SEND INVITE E-MAIL
                            </Button>
                        </CardFooter>
                    </form>
                )}
            </CardBody>
        </Card>
    );
};

export default AddMemberCard;
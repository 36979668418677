import { API_GET, API_POST } from "../api.service";

const API_URL = "register"; // Define the base URL for register-related API calls

const MyTeamAPI = {
    getAllMembersByUserId: async (userid) => {
        try {
            const response = await API_GET(`${API_URL}/GetAllMembersByUserId?id=${userid}`);
            return response;
        } catch (error) {
            console.error("Error fetching members:", error);
            throw error; // Re-throw to be handled by the component
        }
    },
    changeUserState: async (type, id, username) => {
        try {
            const response = await API_POST(`${API_URL}/changeUserState`, {
                Type: type,
                id: id,
                username: username,
            });
            return response;
        } catch (error) {
            console.error("Error changing user state:", error);
            throw error;
        }
    },
    getCount: async (username) => {
        try {
            const response = await API_POST("action/getCount", {
                root: "prospects",
                con: {
                    assignTo: username,
                },
            });
            return response;
        } catch (error) {
            console.error("Error getting count:", error);
            throw error;
        }
    },
    getData: async (admin) => {
        try {
            const response = await API_POST("action/getdata", {
                root: "Configuration",
                con: {
                    context: 'notification',
                    admin: admin,
                },
            });
            return response;
        } catch (error) {
            console.error("Error getting data:", error);
            throw error;
        }
    },
    upsert: async (value, userId, admin) => {
        try {
            const response = await API_POST("action/upsert", {
                root: "Configuration",
                querydata: {
                    name: value.name,
                    context: 'notification',
                    admin: admin,
                },
                body: {
                    ...value,
                    context: 'notification',
                    userId: userId,
                    admin: admin,
                },
            });
            return response;
        } catch (error) {
            console.error("Error upserting data:", error);
            throw error;
        }
    },
    sendCode: async (to_user_id) => {
        try {
            const response = await API_GET(`email/sendCode?user_id=${to_user_id}`);
            return response;
        } catch (error) {
            console.error("Error sending code:", error);
            throw error;
        }
    },
    verify: async (code) => {
        try {
            const response = await API_GET(`email/verify?code=${code}&t=1`);
            return response;
        } catch (error) {
            console.error("Error verifying code:", error);
            throw error;
        }
    },
    updatePermissions: async (data, user) => {
        try {
            const response = await API_POST(`${API_URL}/updatePermissions`, {
                data: data,
                user: user,
            });
            return response;
        } catch (error) {
            console.error("Error updating permissions:", error);
            throw error;
        }
    },
    updateWebPhone: async (IsWebPhone, empId, agentMobile, callType, user) => {
        try {
            const response = await API_POST(`${API_URL}/updateWebPhone`, {
                IsWebPhone: IsWebPhone,
                empId: empId,
                agentMobile: agentMobile,
                callType: callType,
                user: user,
            });
            return response;
        } catch (error) {
            console.error("Error updating web phone:", error);
            throw error;
        }
    },
    resetPhone: async (phone, user) => {
        try {
            const response = await API_POST(`${API_URL}/ResetPhone`, {
                phone: phone,
                user: user,
            });
            return response;
        } catch (error) {
            console.error("Error resetting phone:", error);
            throw error;
        }
    },
    resetPassword: async (password, first_name, user) => {
        try {
            const response = await API_POST(`${API_URL}/ResetPassword`, {
                password: password,
                first_name: first_name,
                user: user,
            });
            return response;
        } catch (error) {
            console.error("Error resetting password:", error);
            throw error;
        }
    },
    changeManager: async (user_id, reporter_id) => {
        try {
            const response = await API_POST(`${API_URL}/changeManager`, {
                user_id: user_id,
                reporter_id: reporter_id,
            });
            return response;
        } catch (error) {
            console.error("Error changing manager:", error);
            throw error;
        }
    },
    changeRole: async (user_id, role) => {
        try {
            const response = await API_POST(`${API_URL}/changeRole`, {
                user_id: user_id,
                role: role,
            });
            return response;
        } catch (error) {
            console.error("Error changing role:", error);
            throw error;
        }
    },
    getPermissions: async (user_id) => {
        try {
            const response = await API_POST(`${API_URL}/getPermissions?user_id=${user_id}`);
            return response;
        } catch (error) {
            console.error("Error getting permissions:", error);
            throw error;
        }
    },
};

export default MyTeamAPI;
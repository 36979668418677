import React, { useState } from "react";
import { Modal, Box, Chip, IconButton } from "@material-ui/core";
import ReceiptIcon from '@material-ui/icons/Receipt';
import ViewDisposition from "../Forms/ViewDisposition";
import Button from "components/CustomButtons/Button.jsx";

const DispositionSelector = ({ disposition, onSelectDisposition }) => {
    console.log("DispositionSelector", disposition)
    const [openModal, setOpenModal] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleConfirmSelection = () => {
        onSelectDisposition(selectedItems);
        handleCloseModal();
    };

    const handleRemoveDisposition = (value) => {
        onSelectDisposition(disposition.filter((item) => item !== value));
    };

    return (
        <>
             <IconButton onClick={handleOpenModal} >
                    <ReceiptIcon />
                </IconButton>
            <div style={{ marginTop: 5 }}>
               

                {disposition && disposition.map((item, index) => (
                    <Chip
                        key={index}
                        label={item.disposition_value}
                        onDelete={() => handleRemoveDisposition(item)}
                        style={{ margin: 2 }}
                    />
                ))}
            </div>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 340, background: '#ffffff' }}>
                    <ViewDisposition
                        Title="Dispositions"
                        user={true}
                        onSelectDisposition={(items) => setSelectedItems(items)}
                    />
                    <Button onClick={handleConfirmSelection} color="info" style={{ marginTop: 10, marginBottom: 10, marginRight: 10, float: 'right' }}>
                        Select
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default DispositionSelector;
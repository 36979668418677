import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Notifications from "@material-ui/icons/Notifications";
// core components
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { connect } from "react-redux";
import "./Styles/Navbar.css";

import { API_POST } from "../../services/api.service";

import { CloseRounded } from "@material-ui/icons";
import { Badge, Grid, Divider} from "@material-ui/core";
import socketIOClient from "socket.io-client";
const { REACT_APP_SERVER_URL } = process.env;
export const base_url = `//${REACT_APP_SERVER_URL}`;


class PushNotification extends React.Component {
    state = {
        open: false,
        profilePopupOpen: false,
        notificationPopup: false,
        notifications: null,
        isMobile: (window.innerWidth <= 760),
        blink: false,
        newNotification: 0,
        socket: null
    };

    userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));


    handleToggleNotification = () => {
        this.setState((state) => ({
            notificationPopup: !state.notificationPopup,
            open: false,
        }));
    };




    handleCloseNotification = (event) => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ open: false, notificationPopup: false });
    };

    removeNotification = async (e) => {
        const { user } = this.props;
        if (user && user.admin) {
            let registerRequest = await API_POST("action/update", {
                root: "notifications",
                querydata: { _id: e._id },
                body: { IsActive: false, read: 1 },
            });
            // this.setState({
            //     notifications: null,
            //     notificationPopup: false,
            //     open: false,
            //     newNotification: 0
            // });
            this.loadNotification();
        }
    }
    onClickNotification = async (val) => {
        console.log(val);
        if (val.prospectId) {
            //ViewProspect/''/1

            this.props.history.push("/admin/ViewProspect/" + val.prospectId + "/1");
        }
    }

    async componentDidMount() {
        setTimeout(() => { this.loadNotification() }, 3000);

    }

    loadNotification = async () => {

        const { user } = this.props;

        if (user && user.admin) {
            console.log(user);
            let registerRequest = await API_POST("action/getdata", {
                root: "notifications",
                con: { user: user && user.email, IsActive: true },
                cols: {},
            });

            var newcalculateNotificationCount = 0;
            registerRequest['data'] && registerRequest['data'].map((val) => {
                if (val.read == 0) {
                    newcalculateNotificationCount++;
                }
            })

            console.log(newcalculateNotificationCount);

            if (registerRequest['data'] && registerRequest['data'].length > 0) {
                this.setState({
                    notifications: registerRequest.data,
                    newNotification: newcalculateNotificationCount
                });
            }
            else {
                this.setState({
                    notifications: null,
                    notificationPopup: false,
                    open: false,
                    newNotification: 0
                });

            }

            //scoket connection
            try {
                var that = this;

                var _base_url = base_url.replace('/api', '');
                console.log("base_url", _base_url);
                const socket = socketIOClient(`${_base_url}`, {
                    transports: ["websocket"],
                    path: "/api/io/socket.io",
                    secure: true,
                    forceNew: true
                });
                socket.emit("new user", user.email);
                socket.on("read_messages", (message) => {
                    try {
                        that.loadNotification();
                    }
                    catch (e) {

                    }
                });

                socket.on("connect", () => {
                    console.log("connect");
                    console.log(socket.connected); // true
                    console.log(socket.id);
                    that.setState({ socket: socket });

                });

                socket.on("disconnect", (reason) => {
                    console.log("disconnect");
                    console.log(socket.connected); // false
                    console.log(socket.id);
                    console.log(reason);
                    setTimeout(() => {
                        socket.emit("reconnect", user.email);
                    }, 1000);
                    that.setState({ socket: null });
                });

                //if (socket.connected) {

                //}
            }
            catch (e) {
                console.log(e);
            }

        }

    }


    render() {
        const { classes, user } = this.props;


        const { open, isMobile, notificationPopup, notifications, newNotification, socket } = this.state;

        return (
            <>

                {socket && socket.connected && <>

                    <Button
                        buttonRef={(node) => {
                            this.anchorEl = node;
                        }}
                        color={"transparent"}
                        aria-label="Notification"
                        aria-haspopup="true"
                        onClick={this.handleToggleNotification}
                    >
                        <Badge badgeContent={newNotification} color="primary">
                            <Notifications />
                        </Badge>


                    </Button>

                    <Poppers
                        open={notificationPopup}
                        anchorEl={this.anchorEl}
                        transition
                        disablePortal
                        className={
                            classNames({ [classes.popperClose]: !notificationPopup }) +
                            " " +
                            classes.pooperNav
                        }
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                id="menu-list-grow"
                                style={{
                                    width: '350px',
                                    height: '400px',
                                    overflow: 'auto',
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={this.handleCloseNotification}>
                                        <MenuList role="menu">

                                            {notifications ?
                                                notifications.map((val, key) => {
                                                    console.log(val);
                                                    return (
                                                        <Grid container >
                                                            <Grid item xs={10} md={10}>
                                                                <MenuItem style={{textWrap:'balance'}} onClick={() => this.onClickNotification(val)}>  {val.message}</MenuItem>
                                                            </Grid>
                                                            <Grid item xs={2} md={2}>
                                                                <MenuItem>

                                                                    <CloseRounded onClick={() => this.removeNotification(val)} style={{ color: 'red' }} />

                                                                </MenuItem>
                                                            </Grid>
                                                            <Grid item xs={12} md={12}>
                                                                <hr></hr>

                                                                </Grid>
                                                        </Grid>



                                                    )

                                                }) : <MenuItem> No Notification Found</MenuItem>
                                            }
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Poppers>
                </>}
            </>
        );
    }
}
const mapstatetoProps = (state) => {
    return {};
};

// //add dispatch to props
// const mapDispatchtoProps = (dispatch) => {
//     return {
//         setSearchValToRedux: (value) =>
//             dispatch(searchVal({ SearchBoxVal: value })),
//     };
// };

//export default withStyles(headerLinksStyle)(HeaderLinks);

export default connect(
    mapstatetoProps
)(withStyles(headerLinksStyle)(PushNotification));

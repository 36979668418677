import React from "react";
import Button from "components/CustomButtons/Button.jsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  floatingButton: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(9),
  },
}));

const FloatingSaveButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button onClick={onClick} color="success" size="sm" className={classes.floatingButton}>
      Save Configuration
    </Button>
  );
};

export default FloatingSaveButton;
// LikeDislikeComponent.jsx (Updated with Mandatory Comment for Dislike)
import React, { useState, useEffect } from 'react';

import { fetchReactions, submitReaction } from "services/reactions/api";
import { IconButton, Typography, Box, makeStyles, TextField, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column', // Arrange buttons and comment vertically
        gap: theme.spacing(1),
        alignItems: 'flex-start', // Align items to the start of the container
    },
    buttonsContainer: { // Container for like/dislike buttons in a row
        display: 'flex',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(1), // Add some space below buttons
    },
    iconButton: {
        // Base styles for icon buttons if needed
    },
    activeButton: {
        color: theme.palette.primary.main,
        // You might need to adjust color based on your theme setup in v4
    },
    count: {
        marginLeft: theme.spacing(0.5),
    },
    commentField: {
        marginTop: theme.spacing(1), // Add space above comment field
    },
    submitCommentButton: {
        marginTop: theme.spacing(1),
    },
}));


const LikeDislikeComponent = ({ itemId, itemType }) => {
    const [likeCount, setLikeCount] = useState(0);
    const [dislikeCount, setDislikeCount] = useState(0);
    const [userReaction, setUserReaction] = useState(null); // 'like', 'dislike', or null
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [comment, setComment] = useState(''); // State for comment input
    const [showCommentInput, setShowCommentInput] = useState(false); // State to control comment input visibility
    const [commentError, setCommentError] = useState(false); // State to manage comment input error

    const classes = useStyles();
    const fetchReactionsData = async () => {
        setLoading(true);
        setError(null);
        try {
            debugger;
            const data = await fetchReactions(itemType, itemId);
            setLikeCount(data.likeCount);
            setDislikeCount(data.dislikeCount);
            setUserReaction(data.userReaction);
            setComment(data.userComment);
            // If user already disliked, show comment input again on re-render
            // if (data.userReaction === 'dislike') {
            //     setShowCommentInput(true);
            // } else {
            //     setShowCommentInput(false); // Hide comment input if reaction is not dislike
            // }
            setLoading(false);
        } catch (err) {
            console.error('Error fetching reactions in component:', err);
            setError('Failed to load reactions.');
            setLoading(false);
        }
    };
    useEffect(() => {
        

        fetchReactionsData();
    }, [itemId, itemType]);

    const handleReaction = async (reactionType) => {
        let result = true;
        setLoading(true);
        setError(null);
        setCommentError(false); // Clear any previous comment error

        try {
            let reactionComment = null; // Initialize comment to null

            if (reactionType === 'dislike') {
                if (!showCommentInput) {
                    // If disliking for the first time, show comment input and exit
                    setShowCommentInput(true);
                    setLoading(false); // Stop loading here, wait for comment submission
                    return;
                } else {
                    // If comment input is shown, use the current comment value
                    reactionComment = comment;
                    if (!comment) {
                        result = false;
                        setCommentError(true); // Set comment error if empty
                        setLoading(false);
                        //return ; // Stop submission if comment is mandatory and empty
                    }
                }
            } else {
                setShowCommentInput(false); // Hide comment input if liking or removing dislike
                setComment(''); // Clear comment when liking or removing dislike
                await submitReaction(itemId, itemType, reactionType, reactionComment); // Pass comment to submitReaction
            }

            if(result)
             fetchReactionsData();

            return result;

        } catch (err) {
            console.error('Error submitting reaction in component:', err);
            setError('Failed to submit reaction.');
            setLoading(false);
        }
    };

    const handleCommentChange = (event) => {
        const comment = event.target.value.trim();
        setComment(comment);
        if(comment){
            setCommentError(false); // Clear error when user starts typing
        }
        else{
            setCommentError(true); // Clear error when user starts typing
        }
        
    };

    const handleSubmitDislike = async () => {
        const result = await handleReaction('dislike');
        if(result){


            setShowCommentInput(false);
            // Call handleReaction with 'dislike' when button is clicked
           await submitReaction(itemId, itemType, 'dislike', comment); // Pass comment to submitReaction
    
        }
        
               
    };


    if (loading) {
        return <div>Loading reactions...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.buttonsContainer}> {/* Container for buttons */}
                <IconButton
                    onClick={() => handleReaction('like')}
                    color={userReaction === 'like' ? 'primary' : 'default'}
                    disabled={loading} // Disable like button when comment input is shown
                    aria-label="like"
                    className={userReaction === 'like' ? classes.activeButton : classes.iconButton}
                >
                    <ThumbUpIcon />
                    <Typography variant="body2" className={classes.count}>{likeCount}</Typography>
                </IconButton>
                <IconButton
                    onClick={() => handleReaction('dislike')}
                    color={userReaction === 'dislike' ? 'primary' : 'default'}
                    disabled={loading}
                    aria-label="dislike"
                    className={userReaction === 'dislike' ? classes.activeButton : classes.iconButton}
                >
                    <ThumbDownIcon />
                    <Typography variant="body2" className={classes.count}>{dislikeCount}</Typography>
                </IconButton>


                {showCommentInput && (
                    <>
                        {/* Popup Dialog */}
                        <Dialog open={showCommentInput} maxWidth="sm" fullWidth>

                            <DialogContent>
                                <TextField
                                    className={classes.commentField}
                                    label="Comment *"
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                    fullWidth
                                    value={comment}
                                    onChange={handleCommentChange}
                                    error={commentError}
                                    helperText={commentError ? "Comment is required for dislike" : ""}
                                    placeholder="Why you dislike this? (Required)"
                                    disabled={loading}
                                    required // Indicate that comment is required visually
                                />
                            </DialogContent>
                            <DialogActions>

                               
                                <Button
                                    // className={classes.submitCommentButton}
                                    // variant="contained"
                                    color="secondary"
                                    onClick={()=> setShowCommentInput(false)}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    // className={classes.submitCommentButton}
                                    // variant="contained"
                                    color="primary"
                                    onClick={handleSubmitDislike}
                                    disabled={loading}
                                >
                                    Submit
                                </Button>
                            </DialogActions>
                        </Dialog>




                    </>
                )}
            </Box>
        </Box>
    );
};

export default LikeDislikeComponent;
import React, { useState } from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControlClassName: {
        width: '100%',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
}));

const ChangePasswordDialog = ({ open, onClose, selectedMember, first_name, newPassword, savePassword, setFirstName, setNewPassword }) => {
    const classes = useStyles();
    const [errors, setErrors] = useState({});

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle id="simple-dialog-title">
                Change User Password
            </DialogTitle>
            <DialogContent>
                <span>
                    for User:{" "}
                    {selectedMember && selectedMember.username}
                </span>

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            labelText="User Name "
                            id="username"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControlClassName,
                            }}
                            inputProps={{
                                type: "text",
                                value: first_name,
                                required: true,
                                onChange: (e) => {
                                    setFirstName(e.target.value);
                                },
                            }}
                        />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            labelText="New Password"
                            id="NewPassword"
                            error={errors.password || errors.invalidEmailOrPassword}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControlClassName,
                            }}
                            inputProps={{
                                type: "password",
                                value: newPassword,
                                autoComplete: "off",
                                required: true,
                                onChange: (e) => {
                                    setNewPassword(e.target.value);
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon className={classes.inputAdornmentIcon}>
                                            lock_outline
                                        </Icon>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <Button
                            color="success"
                            size="sm"
                            onClick={savePassword}
                        >
                            Save
                        </Button>
                        <Button
                            color="danger"
                            size="sm"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </GridItem>
                </GridContainer>
            </DialogContent>
        </Dialog>
    );
};

export default ChangePasswordDialog;
import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
// core components
import DataTable from "react-data-table-component";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CryptoJS from "crypto-js";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { API_POST } from "../../services/api.service";
import Chip from "@material-ui/core/Chip";
import CommonMapping from "./../CommonMapping/CommonMapping";
import InfoBox from "../../components/InfoIcon/InfoIcon";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';


function WhatsappBetyphon(props) {
  const { classes, user, open, defaultData, IsEdit } = props;
  const [hookData, sethookData] = useState([]);
  const [Campaigns, setCampaigns] = useState([]);
  const [userIdInput, setUserIdInput] = useState("");
  const [profileIdInput, setProfileIdInput] = useState("");
  // https://www.tradeindia.com/utils/my_inquiry.html?userid=4696867&profile_id=6260167&key=0155a1bfb1360b02af468f4424a0c4ab&from_date=2021-08-22&to_date=2021-08-23&limit=10&page_no=1
  const [api, setApi] = useState(
    "https://web.betyphon.in/api/public/whatsappBetyphonAPI?cid=${cid}&phone=${phone}&admin=" + user.admin + "&whatsappMessage=${message}&tags=${tagId}"
  );
  const [apid, setApid] = useState(
    "https://web.betyphon.in/api/public/whatsappBetyphonAPI?cid=${cid}&phone=${phone}&admin=" + user.admin + "&whatsappMessage=${message}&tags=${tagId}"
  );
  const [InputType, setInputType] = useState("");
  const [keyInput, setKeyInput] = useState([]);
  const [MappingVal, setMappingVal] = useState([]);
  const [MappingWithMulObject, setMappingWithMulObject] = useState([]);

  const [PreviewData, setPreviewData] = useState([]);
  const [IsShowFieldMapping, setIsShowFieldMapping] = useState(false);
  const [IsShowTestBtn, setIsShowTestBtn] = useState(false);
  const [IsPreviewData, setIsPreviewData] = useState(false);

  const [columns, setcolumns] = useState([]);
  const [mapping, setMapping] = useState([]);
  const [chkResult, setChkResult] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [IsTestBtnClicked, setIsTestBtnClicked] = useState(false);
  const [IsSaved, setIsSaved] = useState(false);
  const [httpMethod, setHttpMethod] = useState("GET"); // Added state for HTTP method

  const childRef = useRef();

  const handleClose = () => {
    props.handleClose();
  };

  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  useEffect(() => {
    if (props.user) {
      loadCampaign();
    }
  }, [props.user]);


  useEffect(() => {
    if (props.user) {
      handleTest()
    }
  }, [InputType, httpMethod]); // Add httpMethod to dependency array




  const loadCampaign = async () => {
    const { user } = props;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { admin: user && user.admin, IsActive: true },
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest.data.length > 0) {
          setCampaigns(registerRequest.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleTest = async () => {
    let url = apid;

    if (InputType)
      url = url.replace("${cid}", InputType._id);

    setApi(url)

  };

  const handleHttpMethodChange = (event) => {
    setHttpMethod(event.target.value);

    //Change default API URL based on selection
    if (event.target.value === "POST") {
      setApid("https://web.betyphon.in/api/public/whatsappBetyphonAPI/${cid}"); //Adjust as needed for your POST endpoint
      setApi("https://web.betyphon.in/api/public/whatsappBetyphonAPI/${cid}");
    } else {
      setApid("https://web.betyphon.in/api/public/whatsappBetyphonAPI?cid=${cid}&phone=${phone}&admin=" + user.admin + "&whatsappMessage=${message}&tags=${tagId}");
      setApi("https://web.betyphon.in/api/public/whatsappBetyphonAPI?cid=${cid}&phone=${phone}&admin=" + user.admin + "&whatsappMessage=${message}&tags=${tagId}");
    }
  };



  let col = [];



  return (
    <GridContainer>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
      <Dialog open={open} fullWidth={true} maxWidth="md">
        <DialogTitle id="customized-dialog-title">Whatsapp Betyphon
          <GridItem style={{ float: "right", marginTop: "10px" }}>
            <InfoBox name={'Integrations.WhatsappBetyphon'} />
          </GridItem>
        </DialogTitle>

        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                id="Name"
                labelText={"Name"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: "Whatsapp Betyphon",
                  name: "Whatsapp Betyphon",
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Autocomplete
                onChange={(event, value) => setInputType(value)}
                id="combo-box-demo"
                size="small"
                options={Campaigns}
                labelText="FieldType"
                name="autoComplete"
                value={InputType || null}
                getOptionLabel={(Campaigns) => Campaigns.name || ""}
                defaultValue="Select"
                renderTags={(value) =>
                  value.map(() => (
                    <Chip variant="outlined" label="Enter Field" />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Campaign "
                  // variant="outlined"
                  />
                )}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="http-method-label">HTTP Method</InputLabel>
                <Select
                  labelId="http-method-label"
                  id="http-method"
                  value={httpMethod}
                  label="HTTP Method"
                  onChange={handleHttpMethodChange}
                >
                  <MenuItem value={"GET"}>GET</MenuItem>
                  <MenuItem value={"POST"}>POST</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                id="api"
                labelText={"API *"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: api,
                  name: "api",
                  onChange: (e) => setApi(e.target.value), // Update the api state on change
                }}
              />

              <br />
            </GridItem>

          </GridContainer>

        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}

export default WhatsappBetyphon;

class GenerateField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChange,
      id,
      options,
      label,
      keyValuePair,
      type = "select",
      disabled = false,
    } = this.props;


    return (
      <GridItem xs={12} sm={12} md={12}>
        <CustomInput
          labelText={label}
          id
          labelProps={{ shrink: true }}
          formControlProps={{
            fullWidth: true,
            multiline: true,
          }}
          customOptions={options}
          inputProps={{
            required: false,
            value,
            defaultValue: value,
            checked: value,
            name: id,
            id: id,
            type: type,
            onChange,
            disabled,
            options: keyValuePair,
          }}
        />
      </GridItem>
    );
  }
}
import React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from '@material-ui/core/styles';
import { userRoles } from "../../../services/api.service";

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

const ChangeRoleDialog = ({ open, onClose, promoteMember }) => {
    const classes = useStyles();

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle id="simple-dialog-title">Change User Role</DialogTitle>
            <List>
                {Object.keys(userRoles).map((item) => (
                    <ListItem
                        button
                        onClick={(e) => promoteMember(e, item)}
                        key={item}
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <i />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={userRoles[item]} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};

export default ChangeRoleDialog;
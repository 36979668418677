import React from "react";
import { TextField, Typography, Divider, Grid } from "@material-ui/core";

const RemarketingImpactSection = ({ remarketingImpact, onChange }) => {
  return (
    <div>
      <Divider style={{ margin: "20px 0" }} />
      <Typography variant="h5">Remarketing Impact</Typography>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label="WhatsApp"
            type="number"
            value={remarketingImpact.whatsapp}
            onChange={(e) => onChange("whatsapp", Number(e.target.value))}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="IVR Calls"
            type="number"
            value={remarketingImpact.ivrCalls}
            onChange={(e) => onChange("ivrCalls", Number(e.target.value))}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Email"
            type="number"
            value={remarketingImpact.email}
            onChange={(e) => onChange("email", Number(e.target.value))}
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default RemarketingImpactSection;
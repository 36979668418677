import React from 'react';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Loader from "components/Loader/Loader.jsx";
import InfoBox from "components/InfoIcon/InfoIcon";
import LikeDislikeComponent from 'components/Reaction/LikeDislikeComponent';

const ManageTeamCard = ({ classes, userInfo, user, managers, history }) => {
    return (
        <Card>
            <CardHeader color="transparent">
                <h3>Manage Teams</h3>
                <GridItem style={{ float: "right", marginTop: "-40px" }}>
                    <InfoBox name={'MyTeam.ManageTeam'} />
                </GridItem>
                <span>
                    You have created{" "}
                    <b>
                        {userInfo && userInfo.TotalUserCount - userInfo.avbUserCount}
                    </b>{" "}
                    out of <b>{userInfo && userInfo.TotalUserCount}</b> members.
                </span>
            </CardHeader>
            <CardBody style={{ minHeight: '200px' }}>
                {!userInfo ? (
                    <Loader />
                ) : (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <b>Owners -</b> <span> {user && user.username}</span>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <b>Account Expire on -</b> <span> {user && window.moment(user.expireon).format("DD-MMM-YYYY")}</span>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <b> Managers - </b>{" "}
                            {managers.length > 0 ? managers.join(",") : "None"}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <b> Customer Success Manager: - </b>
                            {user && user.allocatedTo != null &&
                                <>{user.allocatedToRM.first_name}
                                    {user.allocatedToRM.agentMobile && <>({<a href={'tel:' + user.allocatedToRM.agentMobile}></a>})</>} </>
                            }
                            <br />
                            {
                                user && user.admin == 'info@techmet.in' && <><a onClick={(e) => history.push("/admin/RMAllocated")} > Allocate RM </a></>
                            }
                        </GridItem>
                        <GridItem>
                            <LikeDislikeComponent
                                itemId="MyTeam" // Assuming your item has an _id
                                itemType="MyTeam" // Define a unique itemType
                            />
                        </GridItem>
                    </GridContainer>
                )}
            </CardBody>
        </Card>
    );
};

export default ManageTeamCard;
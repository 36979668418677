import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";

import { API_POST } from "../../services/api.service";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

//import ViewDisposition from "./ViewDisposition";
//import "./Style/Disposition.css";
import DeleteIcon from "@material-ui/icons/Delete";
import { Snackbar } from "@material-ui/core";

import TagInput from "components/Tags/TagInput";
import ViewTags from "../Forms/ViewTags";  // Keep this, it might be useful for displaying related tags
import InfoBox from "../../components/InfoIcon/InfoIcon";

const style = {
  Keywords: { // Changed from Tags to Keywords
    display: "inline-block",
    width: "100%",
  },

  actions: {
    padding: "3px 8px",
    color: "white",
    backgroundColor: "#00a65a",
    borderRadius: "10px",
  },
  frmFields: {
    padding: "3px 8px",
    color: "white",
    backgroundColor: "#3c8dbc",
    borderRadius: "10px",
  },
};

function WhatsappKeywords(props) { // Renamed component
  const { classes, user } = props;

  let frmVal = {
    keyword_value: "", // Renamed field
    admin: user && user.admin,
    createdOn: new Date(),
    isActive: true, // Added isActive for consistency
  };
  const [formValues, setFormValues] = useState(frmVal);
  const [count, setCount] = useState(0);
  const [customFields, setCustomFields] = useState([]); // You might not need this, but I've kept it in case
  const [formOptions, setFormOptions] = useState([]); // You might not need this, but I've kept it in case
  const [keywords, setKeywords] = useState([]); // Renamed state variable

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("Add");
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: 'success'
  });


  const handleClickOpen = () => {
    setMode("Add");
    setFormValues(frmVal);
    setOpen(true);
  };


  const handleClickEditOpen = (item) => {
    setMode("Edit");
    setFormValues(item);
    setOpen(true);
  };



  const handleClose = () => {
    setOpen(false);
  };


  const loadKeywords = async (e) => { // Renamed function
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "whatsappKeywords", // Changed root
        con: { admin: user && user.admin },
        cols: {},
      });

      if (registerRequest && registerRequest.status == 200) {
        setKeywords(registerRequest.data); // Update state

      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };



  const handleChange = async (e) => {
    let formValue = formValues;

    if (e.target && e.target.type === "checkbox") {
      formValue[e.target.id] = e.target.checked;
    } else if (e._isAMomentObject) {
      formValue[props] = e.format();  // Consider renaming 'props' here to something more descriptive
    } else {
      formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
    }

    setFormValues(formValue);
    setCount(count + 1);
  };

  const handleKeywords = async (e) => { // Renamed function
    e.preventDefault();

    let formValue = formValues;
    formValue["admin"] = user && user.admin;
    formValue["createdOn"] = new Date();

    let registerRequest;
    try {
      registerRequest = await API_POST("action/addRecord", {
        root: "whatsappKeywords", // Changed root
        body: formValue,
      });

      if (registerRequest && registerRequest.status == 200) {
        setFormValues(frmVal);
        loadKeywords(e); // Reload keywords
        handleClose();
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };
  const handleEditKeywords = async () => { // Renamed function

    let formValue = formValues;
    let _id = formValues._id;
    formValue["admin"] = user && user.username;
    formValue["updatedOn"] = new Date();
    delete formValue["_id"];

    if (!await keywordUse(formValues)) { // Renamed function
      return true;
    }

    let registerRequest;
    try {
      registerRequest = await API_POST("action/update", {
        root: "whatsappKeywords", // Changed root
        body: formValue,
        querydata: { _id },
      });

      if (registerRequest && registerRequest.status == 200) {
        setFormValues(frmVal);
        loadKeywords(); // Reload keywords
        handleClose();
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };

  useEffect(() => { }, [count]);

  useEffect(() => {
    if (user) {
      loadKeywords(); // Load keywords on user change
    }
  }, [user]);

  const selectfrmField = (_id) => { // Kept this, but you might not need it
    let returnVal = {};
    if (customFields && customFields.length > 0) {
      customFields.forEach((element) => {
        if (element._id == _id) returnVal = element;
      });
    }
    return returnVal;
  };


  const keywordUse = async (item) => { // Renamed function
    let registerRequest;
    try {
      // IMPORTANT:  You need to determine where these keywords are used.
      // Replace "prospects" with the correct collection/table.
      // Replace "tags" and "tag_value" with the correct field names.
      registerRequest = await API_POST("action/getdata", {
        root: "prospects", //  **CHANGE THIS** to the correct collection
        con: { admin: user && user.admin, keywords: { $elemMatch: { keyword_value: item.keyword_value } } }, // **CHANGE THIS**
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest.data && registerRequest.data.length) {
          alert("Keyword is in use");
          return false;
        }
        return true;

      } else {
        return true;
      }


    } catch (error) {
      console.log(error);
      return false;
      //registerRequest = response;
    }

  }

  const deleteRecord = async (item) => {


    let formValue = formValues;
    let _id = formValues._id;
    formValue["admin"] = user && user.username;
    formValue["updatedOn"] = new Date();
    formValue["isActive"] = false;
    delete formValue["_id"];

    console.log(_id);

    let registerRequest;
    try {

      if (!await keywordUse(item)) { // Renamed function
        return true;
      }

      registerRequest = await API_POST("action/update", {
        root: "whatsappKeywords", // Changed root
        body: { isActive: false },
        querydata: { admin: user && user.admin, _id: item._id },
      });

      if (registerRequest && registerRequest.status == 200) {
        loadKeywords(); // Reload keywords
      }


    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }

  };

  const restoreRecord = async (item) => {


    let formValue = formValues;
    let _id = formValues._id;
    formValue["admin"] = user && user.username;
    formValue["updatedOn"] = new Date();
    formValue["isActive"] = false; // This should probably be true
    delete formValue["_id"];

    let registerRequest;
    try {

      if (!await keywordUse(item)) { // Renamed function
        return true;
      }

      registerRequest = await API_POST("action/update", {
        root: "whatsappKeywords", // Changed root
        body: { isActive: true }, // Set to true
        querydata: { admin: user && user.admin, _id: item._id },
      });

      if (registerRequest && registerRequest.status == 200) {
        loadKeywords(); // Reload keywords
      }


    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }

  };

  const keywordNode = (item) => { // Renamed function
    return (
      <li className={classes.Keywords}> {/* Changed class */}
        <span>
          <i class="fa fa-minus" aria-hidden="true" />
          &nbsp;
          {item.keyword_value} 
          {/* ( {item._id} ) Changed field */}
        </span>
        <span style={{ float: "right" }}>
          {
            <Button
              color={"transparent"}
              justIcon={true}
              simple={false}
              aria-label="Edit"
              onClick={(e) => handleClickEditOpen(item)}
            >
              <i class="fa fa-pencil-square-o" aria-hidden="true" />
            </Button>
          }
          {

            item.isActive === false ? (
              <Button size="sm" color="primary" onClick={(e) => restoreRecord(item)}>
                {" "}
                Restore
              </Button>

            ) : (
              <Button size="sm" color="danger" onClick={(e) => deleteRecord(item)}>
                {" "}
                Delete
              </Button>
            )
          }
        </span>
      </li>
    );
  };

  const KeywordList = (Keywords) => { // Renamed function
    return (
      <ul style={{ paddingLeft: "15px" }}>
        {Keywords.map((item, index) => {
          return keywordNode(item); // Renamed function
        })}
      </ul>
    );
  };

  return (
    <>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
      <GridContainer>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {mode} Whatsapp Keyword {/* Changed title */}
            <GridItem style={{ float: "right", marginTop: "10px" }}>
              <InfoBox name={'Form.WhatsappKeywords.Add'} /> {/* Changed name */}
            </GridItem>
          </DialogTitle>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Keyword Value *"  // Changed label
                  id="keyword_value"       // Changed ID
                  formControlProps={{
                    fullWidth: true,
                  }}
                  labelProps={{ shrink: true }}
                  inputProps={{
                    required: true,
                    defaultValue: formValues && formValues.keyword_value, // Changed field
                    name: "keyword_value",  // Changed name
                    onChange: handleChange,
                  }}
                />
              </GridItem>


            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={mode === "Add" ? handleKeywords : handleEditKeywords} // Changed handler
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="white">
              <h3>
                List Whatsapp Keywords {/* Changed title */}
                <Button
                  variant="outlined"
                  color="info"
                  size="sm"
                  style={{ float: "right" }}
                  onClick={handleClickOpen}
                >
                  + Add
                </Button>
                <GridItem style={{ float: "right", marginTop: "10px" }}>
                  <InfoBox name={'Forms.WhatsappKeywords'} /> {/* Changed name */}
                </GridItem>
              </h3>
            </CardHeader>
            <CardBody>
              {keywords && keywords.length > 0
                ? KeywordList(keywords) // Changed variable
                : null}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <ViewTags  // Keep this, in case you want to show related tags
            user={user}
          />

        </GridItem>
      </GridContainer>
    </>
  );

}

WhatsappKeywords.propTypes = { // Renamed component
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(WhatsappKeywords); // Renamed component
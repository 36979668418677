import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Card from "../../../../../components/Card/Card.jsx";
import CardHeader from "../../../../../components/Card/CardHeader.jsx";
import CardBody from "../../../../../components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { API_POST } from "services/api.service";
import DataTable from "react-data-table-component";
import ApiLog from '../../../../SystemLogs/ApiLog.jsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  filterContainer: {
    marginBottom: theme.spacing(2)
  }
}));

const WhatsAppLogs = (props) => {

  return (
    <Card>
        <GridContainer>
          <GridItem xs={12}>
           <ApiLog  {...props } defaultQuery={{method: "whatsapp"}}  />
          </GridItem>
        </GridContainer>
    </Card>
  );
};

WhatsAppLogs.propTypes = {
  user: PropTypes.object.isRequired
};

export default WhatsAppLogs;

import React, { useState } from "react";
import { createLeadScoringConfig, updateLeadScoringConfig } from "../../services/leadscoring/api";
import { Typography, Divider } from "@material-ui/core";
import CategorySection from "./CategorySection";
import FloatingSaveButton from "./FloatingSaveButton";
import RemarketingImpactSection from "./RemarketingImpactSection";
import ResetOnDispositionSection from "./ResetOnDispositionSection";
import CustomInput from "components/CustomInput/CustomInput.jsx";


const LeadScoringForm = ({ config, onClose }) => {
  console.log("config", config);
  const [leadScoring, setLeadScoring] = useState(config ? config : {
    name: "Default",
    defaultScore: 100,
    attributes: {
      disposition: { total: 25, rules: [] },
      entryBase: { total: 30, rules: [] },
      talktime: { total: 30, rules: [] },
      custom: { total: 15, rules: [] },
    },
    remarketingImpact: { whatsapp: 10, ivrCalls: 5, email: 5 },
    resetOnDisposition: [],
  });

  // Handle name change
  const handleNameChange = (event) => {
    setLeadScoring((prev) => ({
      ...prev,
      name: event.target.value,
    }));
  };

  // Handle total score change for each category
  const handleTotalChange = (category, value) => {
    setLeadScoring((prev) => ({
      ...prev,
      attributes: { ...prev.attributes, [category]: { ...prev.attributes[category], total: value } },
    }));
  };

  // Handle rule change
  const handleRuleChange = (category, index, event) => {
    debugger;
    const { name, value } = event.target;
    const updatedRules = [...leadScoring.attributes[category].rules];
    updatedRules[index][name] = value;
    setLeadScoring((prev) => ({
      ...prev,
      attributes: { ...prev.attributes, [category]: { ...prev.attributes[category], rules: updatedRules } },
    }));
  };

  // Add a new rule
  const handleAddRule = (category) => {
    console.log("category", category);
    let newRules = { field: "", operator: "==", value: "", score: 0 };
    if (category === "disposition") {
      newRules.field = "disposition";
    }
    if (category === "talktime") {
      newRules.field = "talktime";
      newRules.operator = ">";
    }
    if (category === "entryBase") {
      newRules.field = "updateStatus";
      newRules.operator = "==";
    }

    setLeadScoring((prev) => ({
      ...prev,
      attributes: {
        ...prev.attributes,
        [category]: { ...prev.attributes[category], rules: [...prev.attributes[category].rules, newRules] },
      },
    }));
  };

  // Remove a rule
  const handleRemoveRule = (category, index) => {
    const updatedRules = leadScoring.attributes[category].rules.filter((_, i) => i !== index);
    setLeadScoring((prev) => ({
      ...prev,
      attributes: { ...prev.attributes, [category]: { ...prev.attributes[category], rules: updatedRules } },
    }));
  };

  // Handle remarketing impact change
  const handleRemarketingImpactChange = (field, value) => {
    setLeadScoring((prev) => ({
      ...prev,
      remarketingImpact: { ...prev.remarketingImpact, [field]: value },
    }));
  };

  // Handle reset on disposition change
  const handleResetOnDispositionChange = (values) => {
    setLeadScoring((prev) => ({
      ...prev,
      resetOnDisposition: values,
    }));
  };

  // Submit lead scoring configuration
  const handleSubmit = async () => {
    if(config){
      await updateLeadScoringConfig(config._id,leadScoring);
    }
    else{
      await createLeadScoringConfig(leadScoring);
    }

    
    alert("Lead Scoring Configuration Saved!");
    debugger;
    onClose();
  };

  return (
    <div>
      {/* <Typography variant="h4">Lead Scoring Configuration</Typography> */}
      {console.log("leadScoring", leadScoring)}
      {/* {JSON.stringify(leadScoring)} */}
      {/* Name Field */}
      <CustomInput
        labelText="Configuration Name"
        id="name"
        formControlProps={{
          fullWidth: true,
        }}
        labelProps={{ shrink: true }}
        inputProps={{
          type: "text",
          defaultValue: leadScoring.name,
          onChange: handleNameChange,
        }}
      />

      {Object.keys(leadScoring.attributes).map((category, index) => (
        <CategorySection
          key={index}
          category={category}
          total={leadScoring.attributes[category].total}
          rules={leadScoring.attributes[category].rules}
          onTotalChange={(value) => handleTotalChange(category, value)}
          onRuleChange={(index, event) => handleRuleChange(category, index, event)}
          onAddRule={() => handleAddRule(category)}
          onRemoveRule={(index) => handleRemoveRule(category, index)}
        />
      ))}

      <RemarketingImpactSection
        remarketingImpact={leadScoring.remarketingImpact}
        onChange={handleRemarketingImpactChange}
      />

      <ResetOnDispositionSection
        resetOnDisposition={leadScoring.resetOnDisposition}
        onChange={handleResetOnDispositionChange}
      />

      <FloatingSaveButton onClick={handleSubmit} />
    </div>
  );
};

export default LeadScoringForm;
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableMobile from "components/DataTableMobile/DataTableMobile.jsx";
import { API_POST } from "../../services/api.service";
import _ from "underscore";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Filters from "./Filter";
import Button from "components/CustomButtons/Button.jsx";
import CallFilters from "./CallFilters";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Dialog from "@material-ui/core/Dialog";
import AddProspect from "../Prospects/AddProspect";
import Loader from "components/Loader/Loader.jsx";
import InfoBox from "../../components/InfoIcon/InfoIcon";
import { getConfigurationByName, getMappedTeamData } from "../common/helper";

// import InfoIcon from "@material-ui/icons/Info";
// import Typography from "@material-ui/core/Typography";


function CallDetail(props) {
  const [loader,setLoader]=useState(false);
  const { user, IsCallDetailsOpen, callDetailsQuery } = props;
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [Query, setQuery] = useState("");
  const countPerPage = 10;
  const [skip, setSkip] = React.useState(0);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));
  const [selectedUser, setSelectedUser] = useState(null);
  const [mobileNo, setMobileNo] = useState(null);
  const [SelectedCampaign, setSelectedCampaign] = useState([]);
  const [open, setOpen] = useState(false);
  const [IsRefresh, setIsRefresh] = useState(false);
  const [openAddProspect, setOpenAddProspect] = useState(false);
  const [CallDetails, setCallDetails] = useState("");
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 760);

  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  const columns = [
    {
      name: "Phone",
      cell: (row) => <span>{   customCell(row,row.pid)    } {row.dataSource && (<span style={{fontSize:10,color:"blue"}}> {row.dataSource} </span>)}</span>,
      
    },

    {
      name: "Action",
      cell: (row) => (
        <span>
          {!row.pid ? (
            <Button
              size="sm"
              color="danger"
              onClick={(e) => {
                handleAddToProspect(e, row);
              }}
            >
              Personal
            </Button>
          ):(
              <Button
                  size="sm"
                  color="success"
                  onClick={(e)=>{
                    props.history.push("/admin/ViewProspect/" + row.pid);
                  }}
              >
                Business
              </Button>


          )}
        </span>
      ),
      width:"180px"
    },
    {
      name: "Connected Status",
      cell: (row) => (
          <span>
          { row.duration==0 ? 'Non-Connected':'Connected' }
        </span>
      ),
      width:"180px"
    },
    {
      name: "Start Date",
      cell: (row) => (
        <span>
          {row.startDate &&
            window.moment(row.startDate).format("DD-MMM-YYYY HH:mm:ss")}
        </span>
      ),
    },
    {
      name: "End Date",
      cell: (row) => (
        <span>
          {row.endDate &&
            window.moment(row.endDate).format("DD-MMM-YYYY HH:mm:ss")}
        </span>
      ),
    },
    {
      name: "Duration",

      cell: (row) => (
        <span>
          {new Date((row.duration ? row.duration : 0) * 1000)
            .toISOString()
            .substr(11, 8)}
        </span>
      ),
    },

    {
      name: "Type",
      selector: row=>row.type,
    },
    {
      name: "Caller",
      selector: row=>row.caller,
      width: "250px",
    },
    {
      name: "Caller Name",
      cell: (row) => (
        <span> { getMappedTeamData(row['caller'])} </span>
       )
      
    },
   
  ];

  const customCell = (value,pid) => {

    return <>{  value.phone}</>;
    // let val = "";

    // if (value.firstName && value.lastName) {
    //   val = value.firstName + " " + value.lastName;
    // } else if (value.firstName) {
    //   val = value.firstName;
    // } else {
    //   val = value.phone;
    // }
    // if (value.pid) {
    //   return (
    //     <Link onClick={handleURL} to={"/admin/ViewProspect/" + value.pid}>
    //       {val}
    //     </Link>
    //   );
    // } else {
    //   return <>{val}</>;
    // }
  };

  const handleURL = () => {
    window.localStorage.setItem("LastURL", window.location.href);
  };

  const handleCallHook = async (e, row) => {
    debugger;
    console.log(row);
    let res = await API_POST("action/CallHooks", {
      module: "CallDetails",
      record: row,
    });

    console.log(res);
  };

  const handleChangePage = async (newPage) => {
    console.log("newPage", newPage);
      setPage(newPage);
      setSkip((newPage - 1) * countPerPage);
   
    return true;
  };

  const handleAddToProspect = async (e, row) => {
    debugger;
    setOpenAddProspect(true);
    setCallDetails(row)
  };

  const pageLoadquery = async () => {
    debugger;
    let query;
    const urlParam = new URLSearchParams(window.location.search);
    let search = urlParam.get("Search");

    if (!search) {
      let data = {
        SelectedCampaign: SelectedCampaign,
        selectedUser: selectedUser,
      };

      query = await handlequery(data, query);
      if (!IsCallDetailsOpen) {
        query = {
          ...query,
          startDate: {
            $gte: new Date(startDate),
            $lt: new Date(endDate),
          },
        };
      }
    } else {

      if (search) {
        search = JSON.parse(atob(search));
      }

      query = { ...search, admin: user && user.admin };
      if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
        query = { ...query, caller: user && user.username };
      }
    }

    return query;
  };

  const handlequery = async (data, query) => {debugger;
    if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
      query = { ...query, caller: user && user.username };
    }

    if (data.selectedUser && data.selectedUser.length > 0) {
      query = { ...query, caller: { ["$in"]: data.selectedUser } };
    }

    if (data.mobileNo  && data.mobileNo.length > 0) {
      query = { ...query, phone: { $regex: '^'+data.mobileNo } };
    }

    if (data.startDate && data.endDate) {
      query = {
        ...query,
        startDate: {
          $gte: new Date(data.startDate),
          $lt: new Date(data.endDate),
        },
      };
    }
    query = { ...query, admin: user && user.admin };

    if(data.callType){
      query = { ...query,callType:data.callType };
    }

    if(data.type !='select'){
      query = { ...query,type:data.type };
    }

    if(data.TotalCalls !='select'){

      if(data.TotalCalls==1)  query = { ...query,duration:{ $gte : 1 } };

      if(data.TotalCalls==0)  query = { ...query,$or:[{duration:{$exists:false} },{ duration:"0"} ]  };

    }
    return query;
  };

  const loadCallRecordData = async () => {
    debugger;

   


    if (!user || (user && !user.admin)) {
      return;
    }
    let con;debugger;
    if (!IsCallDetailsOpen) {debugger;
      con = await pageLoadquery();
    } else {debugger;
      con = { ...con, phone: { $regex: '^'+callDetailsQuery.phone } };
      if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
        con = { ...con, caller: user && user.username };
      }
    }

    con = { ...con };
    debugger;
    let body = {
      limit: countPerPage,
      skip: skip,
      sort: { startDate: -1 },
    };

  

    if(con.callType) {
      if(con.callType=='personal'){
        con = {...con, pid: {$exists: false}};
      }else{

        con = {...con, pid: {$exists: true}};
      }
      delete con.callType;
    }

    let configHidePersonalCall = await getConfigurationByName(user,"hidePersonalCallDetail");

    setQuery(con);debugger;
    let res = await API_POST("action/getPaginationDataActivity", {
      root: "CallDetails",
      querydata: con,
      body: body,
    });
    debugger;
    if(user && user.role === 'agent'){
      res.data.activityData = res.data.activityData  && res.data.activityData.filter((v) =>{ if(configHidePersonalCall && !v.pid){ return false; }else{ return true}  })
    }
    setData(res.data || []);
     setLoader(false);
  
  };

  useEffect(() => {
    setLoader(true)
    loadCallRecordData();   
  }, [page]);

  const handleUrl = async (data) => {
    let query;
    query = await handlequery(data, query);
    let search = btoa(JSON.stringify(query));
    const { history } = props;


    history.push({
      pathname: `/admin/CustActivity`,
      search: `tab=1&Search=${search}`,
    });
    //loadCallRecordData();
  };

  const handleExportExcel = async (e) => {
    let downloadFile = await API_POST(`action/ExportExcel`, {
      querydata: Query,
      body: { admin: user.admin, caller: user.username, page: "CallDetails" },
    });
    if (downloadFile.status == 200) {
      setNotify({
        open: true,
        message: "File is in process,Please check in system log section.",
        color: "success",
      });
    }
  };

  const filterData = (data) => {debugger;

  console.log(data);
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setMobileNo(data.mobileNo);
    setSelectedUser(data.selectedUser);
    setSelectedCampaign(data.SelectedCampaign);
    setIsRefresh(true);
    // setTimeout(function(){ loadCallRecordData()}, 500)
    setOpen(false);
    handleUrl(data);
  };
  useEffect(() => {
    if (IsRefresh) {
      loadCallRecordData();
      setIsRefresh(false);
    }
  }, [IsRefresh]);



  return (
    <>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />

      <Dialog maxWidth="md"
        onClose={() => setOpenAddProspect(false)}
        open={openAddProspect}
      >
        <AddProspect mandatoryFields = {["cid"]} initiateCheck={true} CloseAddProspectPopup={()=>{setOpenAddProspect(false)}} IsShowInCallDetail={true} user={user} data ={CallDetails} OpenAddProspect={()=>{setOpenAddProspect(false)}}/>
      </Dialog>

      {/* <Card>
        <CardHeader color="transparent"> */}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h3>
                Call Details
                <span style={{ float: "right", fontSize: "15px" }}>
                  {!IsCallDetailsOpen && (
                    <Button
                      size="sm"
                      title="Filter"
                      color="info"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <i className="fa fa-filter" /> &nbsp; Filter
                    </Button>
                  )}
                  {["manager", "owner"].indexOf(user && user.role) > -1 && (
                    <Button
                      size="sm"
                      color="transparent"
                      justIcon
                      title="Export To Excel"
                      onClick={() => {
                        handleExportExcel();
                      }}
                    >
                      <GetAppOutlinedIcon />
                    </Button>
                  )}
                </span>
                <GridItem  style={{float: "right", marginTop: "10px"}}>
                  <InfoBox name={'Activity.CallDetail'} />
                </GridItem>
              </h3>
            </GridItem>

            {open && (
              <CallFilters
              Recording_IsShowField={true}
                open={open}
                handleClose={() => {
                  setOpen(false);
                }}
                filterData={filterData}
                user={user}
                showCallType={true}
                showCommunicationType={true}
                showCallConnectedFilter={true}
              />
            )}
          </GridContainer>
        {/* </CardHeader> */}



      

            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{minHeight: '200px', padding: '0 20px !important'}}>
              {data && !data.activityData && <div> <Loader></Loader></div>}

              {data && data.activityData && !isMobile &&   <DataTable
                    columns={columns}
                    data={data.activityData || []}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={data.total}
                    paginationPerPage={countPerPage}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={handleChangePage}
                />}
              {data && data.activityData && isMobile &&   <DataTableMobile
                    newGrid = {true}
                    user={user}
                    columns={columns}
                    history={props.history}
                    setOpenAddProspect = {setOpenAddProspect}
                    data={data.activityData || []}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={data.total}
                    paginationPerPage={countPerPage}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={handleChangePage}
                />}
              </GridItem>
            </GridContainer>

      

      {/* </Card> */}
    </>
  );
}

export default CallDetail;
